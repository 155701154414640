import React, { ReactNode } from "react";
import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";
import { FullScreenLoader } from "./components/FullScreenLoader/FullScreenLoader";
import { SideMenu } from "./components/SideMenu/SideMenu";
import { LoginPageWrapper } from "./pages/LoginPage/LoginPage";
import { AuthenticationService, AuthUser } from "./services/common/AuthenticationService";
import { ServiceInitializationEvent, ServiceInitializer } from "./services/ServiceInitializer";
import "./App.scss";

import { Subscription } from "rxjs";

interface AppState {
    isLoading: boolean;
    isAuthenticated: boolean;
    isInitialized: boolean;
}

interface AppProps { }

export class App extends React.Component<AppProps, AppState> {
    private authenticationService: AuthenticationService;
    private isServiceInitializerRunning: boolean = false;
    private initializationStatusSubscription?: Subscription;

    constructor(props: AppProps) {
        super(props);

        this.state = {
            isLoading: true,
            isAuthenticated: false,
            isInitialized: false,
        };

        this.authenticationService = AuthenticationService.getInstance();
        this.authenticationService.subscribeToAuthStateChanges(this.onAuthStateChange);
        this.initializationStatusSubscription = ServiceInitializer.getServiceInitializationStatusObservable().subscribe(this.onInitializationStatusEvent);
    }

    private onInitializationStatusEvent = (event: ServiceInitializationEvent) => {
        this.isServiceInitializerRunning = false;
        const isInitialized: boolean = event.status === "DONE";
        this.initializationStatusSubscription?.unsubscribe();
        // TODO: Log: Initialization has completed (App.tsx) => status: event.status
        this.setState({ isLoading: false, isInitialized });
    };

    private onAuthStateChange = async (user: AuthUser | null): Promise<void> => {
        if (user) {
            if (!this.isServiceInitializerRunning) {
                this.isServiceInitializerRunning = true;
                ServiceInitializer.runServiceInitManager(user.role);
            }
            this.setState({ isAuthenticated: true });
        } else {
            this.setState({ isAuthenticated: false, isLoading: false });
        }
    };

    private renderRoutes = () => {
        const { isAuthenticated, isInitialized } = this.state;

        return (
            <Routes>
                {isAuthenticated ? (
                    <>
                        {isInitialized && <Route path="/gate-pass/*" element={<SideMenu />} />}
                        {isInitialized && <Route path="*" element={<Navigate to="/gate-pass/gate-passes" />} />}
                    </>
                ) : (
                    <>
                        <Route path="/gate-pass/login" element={<LoginPageWrapper />} />
                        <Route path="*" element={<Navigate to="/gate-pass/login" replace />} />
                    </>
                )}
            </Routes>
        );
    };

    render(): ReactNode {
        const { isLoading } = this.state;

        return (
            <div className="app-container">
                {isLoading ? (
                    <FullScreenLoader />
                ) : (
                    <Router>
                        {this.renderRoutes()}
                    </Router>
                )}
            </div>
        );
    }
}