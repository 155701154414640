import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Component } from 'react';
import { ParsedGatePassData } from '../../../defs/common';
import { GatePassHistoryEvent, GatePassItem } from '../../../services/common/GatePassManagerService';
import { formatDateTimeEpoch, getGatePassTimeLineStatusDisplayValue, getUnitOfMeasurementDisplayValue, getUserNameById } from '../../../utils/utils';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 20,
    },
    section: {
        marginTop: 20,
        marginBottom: 10,
    },
    plantName: {
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 10,
        backgroundColor: '#BBDEFB',
        color: '#1565C0',
        paddingTop: 10,
        paddingBottom: 10
    },
    header: {
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 5,
    },
    gpNo: {
        fontSize: 13,
        textAlign: 'center',
        marginBottom: 5,
    },
    dataItem: {
        fontSize: 11,
        textAlign: 'center',
        marginBottom: 5,
    },
    subheader: {
        fontSize: 15,
        textAlign: 'center',
        marginBottom: 10,
    },
    table: {
        width: '90%',
        margin: 'auto'
    },
    tableHeaderRow: {
        flexDirection: 'row',
        backgroundColor: '#EBF5FB'
    },
    tableRow: {
        flexDirection: 'row',
        borderBottom: '1px solid #EAECEE',
    },
    tableCol: {
        flex: 1,
        padding: 5,
    },
    tableHeaderCell: {
        fontSize: 11,
        fontWeight: 'bold'
    },
    tableCell: {
        fontSize: 10,
    },
    itemDetailsTable: {
        width: '50%',
        margin: 'auto'
    },
    itemDetailsLabelTableCell: {
        fontSize: 10,
        width: 100,
        textAlign: 'right',
    },
    itemDetailsValueTableCell: {
        fontSize: 11,
        fontWeight: 'bold'
    },
    itemDetailsTableRow: {
        flexDirection: 'row'
    },
    footer: {
        textAlign: 'center',
        fontSize: 10,
        marginTop: 'auto',
        color: '#AEB6BF'
    },
    generatedAt: {
        textAlign: 'center',
        fontSize: 8,
        marginTop: 5,
        color: '#AEB6BF'
    }
});

interface GatePassPDFDocumentProps {
    gatePassData: ParsedGatePassData;
    gatePassItems: GatePassItem[],
    gatePassHistory: GatePassHistoryEvent[],
}

interface GatePassPDFDocumentState { }

export class GatePassPDFDocument extends Component<GatePassPDFDocumentProps, GatePassPDFDocumentState> {
    constructor(props: GatePassPDFDocumentProps) {
        super(props);

        this.state = {};
    }

    render() {
        const { gatePassData, gatePassItems, gatePassHistory } = this.props;
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <Text style={styles.plantName}>Yugadanavi Power Plant</Text>
                        <Text style={styles.header}>GATE PASS</Text>
                        <Text style={styles.gpNo}>{`No: ${gatePassData.gatePassNo}`}</Text>
                    </View>

                    <View style={styles.section}>
                        <View style={styles.itemDetailsTable}>
                            <View style={styles.itemDetailsTableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.itemDetailsLabelTableCell}>Gate Pass Type:</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.itemDetailsValueTableCell}>{gatePassData.type === "" ? "-" : gatePassData.type}</Text>
                                </View>
                            </View>

                            <View style={styles.itemDetailsTableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.itemDetailsLabelTableCell}>Checkout Date:</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.itemDetailsValueTableCell}>{gatePassData.checkoutDate === "" ? "-" : gatePassData.checkoutDate}</Text>
                                </View>
                            </View>

                            <View style={styles.itemDetailsTableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.itemDetailsLabelTableCell}>Return Date:</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.itemDetailsValueTableCell}>{gatePassData.returnDate === "" ? "-" : gatePassData.returnDate}</Text>
                                </View>
                            </View>

                            <View style={styles.itemDetailsTableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.itemDetailsLabelTableCell}>Holder:</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.itemDetailsValueTableCell}>{gatePassData.holderUser === "" ? "-" : gatePassData.holderUser}</Text>
                                </View>
                            </View>

                            <View style={styles.itemDetailsTableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.itemDetailsLabelTableCell}>Approver:</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.itemDetailsValueTableCell}>{gatePassData.approverUser === "" ? "-" : gatePassData.approverUser}</Text>
                                </View>
                            </View>

                            <View style={styles.itemDetailsTableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.itemDetailsLabelTableCell}>Location:</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.itemDetailsValueTableCell}>{gatePassData.location === "" ? "-" : gatePassData.location}</Text>
                                </View>
                            </View>

                            <View style={styles.itemDetailsTableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.itemDetailsLabelTableCell}>Vehicle No:</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.itemDetailsValueTableCell}>{gatePassData.vehicleNo === "" ? "-" : gatePassData.vehicleNo}</Text>
                                </View>
                            </View>

                            <View style={styles.itemDetailsTableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.itemDetailsLabelTableCell}>Reason:</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.itemDetailsValueTableCell}>{gatePassData.reason === "" ? "-" : gatePassData.reason}</Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.subheader}>Details of Items</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHeaderRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeaderCell}>Item Code</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeaderCell}>Description</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeaderCell}>Serial no</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeaderCell}>Unit of Meas.</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeaderCell}>Total Qty</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeaderCell}>Returnable Qty</Text>
                                </View>
                            </View>
                            {gatePassItems.map((item, key) => (
                                <View style={styles.tableRow} key={key}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{item.itemCode}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{item.itemName}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{item.serialNumber ?? ""}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{getUnitOfMeasurementDisplayValue(item.measurementUnit)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{item.totalQuantity}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{gatePassData.type === "Returnable" ? item.remainingQuantity : "-"}</Text>
                                    </View>
                                </View>
                            ))}
                        </View>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.subheader}>History</Text>
                        <View style={styles.table}>
                            <View style={styles.tableHeaderRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeaderCell}>Date & Time</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeaderCell}>Action</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeaderCell}>Performed By</Text>
                                </View>
                            </View>
                            {gatePassHistory.map((item, key) => (
                                <View style={styles.tableRow} key={key}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{formatDateTimeEpoch(item.committedAt)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{getGatePassTimeLineStatusDisplayValue(item)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{getUserNameById(item.committedABy)}</Text>
                                    </View>
                                </View>
                            ))}
                        </View>
                    </View>

                    <Text style={styles.footer}>This is a system generated Gate Pass</Text>
                    <Text style={styles.generatedAt}>{`Generated at: ${new Date().toLocaleString().replace('T', ' ').split('.')[0]} `}</Text>
                </Page>
            </Document>
        );
    }
}