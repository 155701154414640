import { Observable, Subject } from "rxjs";
import { ServiceInitializationStatus, UserRole } from "../../defs/common";
import { FirebaseService } from "./FirebaseService";
import { Auth, User, UserCredential, signInWithEmailAndPassword, setPersistence, browserSessionPersistence, onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";

export interface AuthUser {
    userId: string,
    displayName: string;
    email: string;
    role: UserRole;
}

interface InitializationStatusEvent {
    status: ServiceInitializationStatus;
}

export class AuthenticationService {

    private static instance: AuthenticationService | null;
    private firebaseService: FirebaseService;
    private auth: Auth;
    private initializationStatusSubject: Subject<InitializationStatusEvent>;
    private currentUser: AuthUser | null;




    private constructor() {
        AuthenticationService.instance = null;
        this.firebaseService = FirebaseService.getInstance();
        this.auth = this.firebaseService.getAuthentication();
        this.initializationStatusSubject = new Subject<InitializationStatusEvent>();
        this.currentUser = null;
    }

    public subscribeToAuthStateChanges(handleAuthStateChange: (user: AuthUser | null) => void): void {
        onAuthStateChanged(this.auth, async (user: User | null) => {
            if (user === null) return handleAuthStateChange(null);

            const authUser: AuthUser = await this.getAuthUser(user);
            return handleAuthStateChange(authUser);
        });
    }




    public static getInstance(): AuthenticationService {
        if (!this.instance) {
            this.instance = new AuthenticationService();
        }

        return this.instance;
    }




    public init = async (): Promise<void> => {
        try {
            this.currentUser = await this.getCurrentAuthUser();
            setTimeout(() => this.initializationStatusSubject.next({ status: "DONE" }));
        } catch (error: any) {
            console.log("🚀 > file: AuthenticationService.ts:64 > AuthenticationService > init > error:", error)
            // TODO: Log and Error Handle
            setTimeout(() => this.initializationStatusSubject.next({ status: "ERROR" }));
        }
    }

    private async getAuthUser(firebaseAuthUser: User): Promise<AuthUser> {
        const idTokenResult = await firebaseAuthUser.getIdTokenResult();
        return {
            userId: firebaseAuthUser.uid,
            displayName: firebaseAuthUser.displayName ?? "",
            email: firebaseAuthUser.email ?? "",
            role: idTokenResult.claims.role as UserRole
        }
    }

    public async signIn(email: string, password: string): Promise<AuthUser> {
        try {
            await setPersistence(this.auth, browserSessionPersistence);
            const userCredential: UserCredential = await signInWithEmailAndPassword(this.auth, email, password);
            const { user } = userCredential;
            const authUser: AuthUser = await this.getAuthUser(user);

            return Promise.resolve(authUser)
        } catch (error: any) {
            // const errorCode: string = error?.code ?? "NOT_SPECIFIED";
            // const errorMessage: string = error?.message ?? "NOT_SPECIFIED"; 
            return Promise.reject(error)
        }
    }

    public async resetPassword(email: string): Promise<void> {
        try {
            await sendPasswordResetEmail(this.auth, email);
            return Promise.resolve()
        } catch (error: any) {
            return Promise.reject(error)
        }
    }

    public async signOut(): Promise<void> {
        try {
            await this.auth.signOut();
            return Promise.resolve()
        } catch (error: any) {
            return Promise.reject(error)
        }
    }

    public getInitializationStatusObservable = (): Observable<InitializationStatusEvent> => {
        return this.initializationStatusSubject.asObservable();
    };

    private async getCurrentAuthUser(): Promise<AuthUser> {
        const currentUser: User | null = this.auth.currentUser;

        if (!currentUser) {
            // TODO: Log and Error message change
            throw new Error("Current user is null");
        };
        const authUser: AuthUser = await this.getAuthUser(currentUser);
        return authUser;
    }

    public getCurrentUser = (): AuthUser => {

        if (!this.currentUser) {
            throw new Error("User is null");
        }

        return this.currentUser;
    }


}