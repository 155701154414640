import { HistoryOutlined, ProductOutlined, RedoOutlined, StarOutlined, UploadOutlined, UserOutlined, VerticalAlignTopOutlined, WarningOutlined } from "@ant-design/icons";
import { Avatar, Button, Layout, Menu } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import * as React from "react";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { UserRole } from "../../defs/common";
import { GatePassesPage } from "../../pages/GatePassesPage/GatePassesPage";
import { InactiveGatePassPage } from "../../pages/InactiveGatePassPage/InactiveGatePassPage";
import { ItemsPage } from "../../pages/ItemsPage/ItemsPage";
import { MyGatePassesPage } from "../../pages/MyGatePassesPage/MyGatePassesPage";
import { PendingApprovalsPage } from "../../pages/PendingApprovalsPage/PendingApprovalsPage";
import { PendingCheckoutsPage } from "../../pages/PendingCheckoutsPage/PendingCheckoutsPage";
import { PendingReturnsPage } from "../../pages/PendingReturnsPage/PendingReturnsPage";
import { UsersPage } from "../../pages/UsersPage/UsersPage";
import { AuthenticationService, AuthUser } from "../../services/common/AuthenticationService";
import { getUserRoleDisplayValue } from "../../utils/utils";
import { Toast } from "../Toast/Toast";
import "./SideMenu.scss";

interface SideMenuItem {
    name: string;
    route: string;
    icon: any;
}

const OpenGatePassesMenuItem: SideMenuItem = { name: "Open Gate Passes", route: "gate-passes", icon: <UploadOutlined /> };
const ClosedGatePassesMenuItem: SideMenuItem = { name: "Closed Gate Passes", route: "inactive-gate-passes", icon: <HistoryOutlined /> };
const MyGatePassesMenuItem: SideMenuItem = { name: 'My Gate Passes', route: 'my-gate-passes', icon: <StarOutlined /> };
const PendingApprovalsMenuItem: SideMenuItem = { name: 'Pending Approvals', route: 'pending-approvals', icon: <WarningOutlined /> };
const PendingCheckoutsMenuItem: SideMenuItem = { name: 'Pending Checkouts', route: 'pending-checkouts', icon: <VerticalAlignTopOutlined /> };
const PendingReturnsMenuItem: SideMenuItem = { name: 'Pending Returns', route: 'pending-returns', icon: <RedoOutlined /> };
const ItemsMenuItem: SideMenuItem = { name: "Items", route: "items", icon: <ProductOutlined /> };
const UsersMenuItem: SideMenuItem = { name: "Users", route: "users", icon: <UserOutlined /> };

const adminSideMenuItems: SideMenuItem[] = [
    OpenGatePassesMenuItem,
    ClosedGatePassesMenuItem,
    MyGatePassesMenuItem,
    PendingApprovalsMenuItem,
    PendingCheckoutsMenuItem,
    PendingReturnsMenuItem,
    ItemsMenuItem,
    UsersMenuItem
];

const approverSideMenuItems: SideMenuItem[] = [
    OpenGatePassesMenuItem,
    ClosedGatePassesMenuItem,
    MyGatePassesMenuItem,
    PendingApprovalsMenuItem,
    ItemsMenuItem
];

const securityRoomSideMenuItems: SideMenuItem[] = [
    OpenGatePassesMenuItem,
    ClosedGatePassesMenuItem,
    PendingCheckoutsMenuItem
];

const toolRoomSideMenuItems: SideMenuItem[] = [
    OpenGatePassesMenuItem,
    ClosedGatePassesMenuItem,
    PendingReturnsMenuItem,
    ItemsMenuItem
];

const normalUserRoomSideMenuItems: SideMenuItem[] = [
    OpenGatePassesMenuItem,
    ClosedGatePassesMenuItem,
    MyGatePassesMenuItem,
    ItemsMenuItem
];

interface SideMenuProps { }

interface SideMenuState {
    isLogoutInProgress: boolean;
    currentUser: AuthUser | null;
}

export const SideMenu: React.FC<SideMenuProps> = () => {
    const [state, setState] = React.useState<SideMenuState>({
        isLogoutInProgress: false,
        currentUser: null,
    });

    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        const fetchUser = async () => {
            const currentUser = await AuthenticationService.getInstance().getCurrentUser();
            setState((prevState) => ({ ...prevState, currentUser }));
        };
        fetchUser();
    }, []);

    const logout = async (): Promise<void> => {
        try {
            setState((prevState) => ({ ...prevState, isLogoutInProgress: true }));
            await AuthenticationService.getInstance().signOut();
            navigate("/login");
        } catch (error: any) {
            Toast.error("Logout", "User logout failed!");
        } finally {
            setState((prevState) => ({ ...prevState, isLogoutInProgress: false }));
        }
    };

    const getSideMenuItemsForUser = (): SideMenuItem[] => {
        const { currentUser } = state;

        if (currentUser === null) {
            return [];
        }

        switch (currentUser.role) {
            case UserRole.ADMIN:
                return adminSideMenuItems;
            case UserRole.USER_LEVEL_0:
                return approverSideMenuItems;
            case UserRole.USER_LEVEL_1:
                return normalUserRoomSideMenuItems;
            case UserRole.SECURITY_OFFICER:
                return securityRoomSideMenuItems;
            case UserRole.TOOL_ROOM_OFFICER:
                return toolRoomSideMenuItems;
            default:
                return [];
        }
    };

    const sideMenuItems = getSideMenuItemsForUser();
    const selectedKey = sideMenuItems.findIndex(item => location.pathname === `/gate-pass/${item.route}`).toString();

    return (
        <Layout>
            <Sider
                className="side-menu-container"
                breakpoint="lg"
                collapsedWidth="0"
            >
                <div className="header-logo-container">Yugadanavi GPS</div>

                <Menu theme="dark" mode="inline" defaultSelectedKeys={['0']} selectedKeys={[selectedKey]}>
                    {sideMenuItems.length > 0 &&
                        sideMenuItems.map((item, index) => (
                            <Menu.Item className="side-menu-item" key={`${index}`} icon={item.icon}>
                                <Link to={item.route}>{item.name}</Link>
                            </Menu.Item>
                        ))}
                </Menu>

                {state.currentUser !== null && <div className="user-details">
                    <Avatar size="large" className="user-avatar" shape="circle" icon={<UserOutlined />} />
                    <div className="user-data">
                        <div className="name">{state.currentUser.displayName}</div>
                        <div className="role">{getUserRoleDisplayValue(state.currentUser.role)}</div>
                    </div>
                </div>}

                <Button
                    type="primary"
                    danger
                    className="log-out-btn"
                    onClick={logout}
                    loading={state.isLogoutInProgress}
                    disabled={state.isLogoutInProgress}
                >
                    Logout
                </Button>
            </Sider>

            <Layout>
                <Content style={{}}>
                    <div className="side-menu-page-container">
                        {state.currentUser !== null && <Routes>
                            <Route path="gate-passes" element={<GatePassesPage />} />
                            <Route path="inactive-gate-passes" element={<InactiveGatePassPage />} />
                            <Route path="my-gate-passes" element={<MyGatePassesPage />} />
                            <Route path="pending-approvals" element={<PendingApprovalsPage />} />
                            <Route path="pending-checkouts" element={<PendingCheckoutsPage />} />
                            <Route path="pending-returns" element={<PendingReturnsPage />} />
                            <Route path="users" element={<UsersPage />} />
                            <Route path="items" element={<ItemsPage />} />
                        </Routes>}
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};