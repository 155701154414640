import { MinusCircleOutlined, PlusOutlined, RollbackOutlined } from "@ant-design/icons";
import { AutoComplete, Button, DatePicker, Divider, Form, FormInstance, Input, InputNumber, Select, Table } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { UserRole } from "../../../defs/common";
import { ItemManagerService } from "../../../services/admin/ItemManagerService";
import { UserManagerService } from "../../../services/admin/UserManagerService";
import { FirebaseService } from "../../../services/common/FirebaseService";
import { GatePassManagerService } from "../../../services/common/GatePassManagerService";
import { getUnitOfMeasurementDisplayValue, getUnitOfMeasurements } from "../../../utils/utils";
import { GenericModal } from "../../GenericModal/GenericModal";
import { Toast } from "../../Toast/Toast";
import './CreateGatePassForm.scss';

const disablePastDates = (current: Dayjs | null): boolean => {
    return current ? current.isBefore(dayjs().startOf('day')) : false;
};

interface CreateGatePassFormProps {
    onFormFinish: () => void;
    onFormClose: () => void;
}

interface CreateGatePassFormState {
    selectedGatePassItems: any[];
    allItems: any[];
    allUsers: any[];
    isNewItemModalVisible: boolean;
    isGatePassCreateInProgress: boolean;
    isItemCreateInProgress: boolean;
    isCreatingNewUser: boolean;
    itemsAutoCompleteOptions: { value: string; }[];
    isAutoCompleteItemSelected: boolean;
    selectedGatePassType: string | undefined;
    isCheckoutDateSelected: boolean;
    checkoutDate: Dayjs | null;
}

export class CreateGatePassForm extends React.Component<CreateGatePassFormProps, CreateGatePassFormState> {
    private createUser = FirebaseService.getInstance().getHTTPSCallableFunction("createUser");

    private mainFormRef: React.RefObject<FormInstance>;
    private itemsFormRef: React.RefObject<FormInstance>;

    constructor(props: CreateGatePassFormProps) {
        super(props);

        this.state = {
            selectedGatePassItems: [],
            allItems: [],
            allUsers: [],
            isNewItemModalVisible: false,
            isItemCreateInProgress: false,
            isGatePassCreateInProgress: false,
            isCreatingNewUser: false,
            itemsAutoCompleteOptions: [],
            isAutoCompleteItemSelected: false,
            selectedGatePassType: undefined,
            isCheckoutDateSelected: false,
            checkoutDate: null
        };

        this.mainFormRef = React.createRef();
        this.itemsFormRef = React.createRef();
    }

    componentDidMount(): void {
        this.loadInitialData();
    }

    private loadInitialData = () => {
        const allItems = this.loadItemData();
        const itemsAutoCompleteOptions = allItems.map((item) => ({ value: item.itemName }));

        this.setState({
            allUsers: this.loadUserData(),
            allItems,
            itemsAutoCompleteOptions,
        });
    };

    private loadUserData = (): any[] => {
        try {
            return UserManagerService.getInstance().getAllUsers(); // need a filter
        } catch (error) {
            console.error("Error downloading users", error ?? "");
            return [];
        }
    };

    private loadItemData = (): any[] => {
        try {
            return ItemManagerService.getInstance().getAllItems();
        } catch (error) {
            console.error("Error downloading items", error ?? "");
            return [];
        }
    };

    private onGatePassFormFinish = async (values: any) => {
        const { isCreatingNewUser } = this.state;

        this.setState({ isGatePassCreateInProgress: true });

        if (isCreatingNewUser) {
            if (!values?.newGatePassHolderFirstName || !values?.newGatePassHolderLastName) {
                Toast.error("Error creating gate pass", "Please fill the required fields for new user");
                this.setState({ isGatePassCreateInProgress: false });
                return;
            }

            const createUserResponse: any = await this.createUser({
                firstName: values?.newGatePassHolderFirstName,
                lastName: values?.newGatePassHolderLastName,
                email: "",
                password: "",
                role: UserRole.GUEST_USER
            });

            const createdUserId = createUserResponse?.data?.data?.userId;

            if (createUserResponse?.data?.status === "OK" && createdUserId) {
                Toast.success("User created successfully");
                this.createGatePass({ ...values, gatePassHolder: createdUserId });

            } else if (createUserResponse?.data?.status === "NOT_OK") {
                const errorData: any = createUserResponse?.data?.error;
                Toast.error(errorData?.message, errorData?.data?.errorInfo?.message);
                this.setState({ isGatePassCreateInProgress: false, isCreatingNewUser: false });
            }
        } else {
            this.createGatePass(values);
        }
    };

    private createGatePass = (values: any) => {
        const { selectedGatePassItems } = this.state;

        if (selectedGatePassItems.length === 0) {
            Toast.error("Error creating gate pass", "Gate pass should have at least one item");
            this.setState({ isGatePassCreateInProgress: false, isCreatingNewUser: false });
            return;
        }

        GatePassManagerService.getInstance()
            .createGatePass({
                type: values?.type,
                checkoutDate: values.checkOutDate ? values.checkOutDate.unix() : null,
                returnDate: values.returnDate ? values.returnDate.unix() : null,
                location: values?.location,
                reason: values?.reason,
                holderUserId: values?.gatePassHolder,
                vehicleNo: values?.vehicleNo ?? null,
                approverUserId: values?.gatePassApprover,
                items: selectedGatePassItems,
            })
            .then(() => {
                Toast.success("Gate pass created successfully");
                this.props.onFormFinish();
            })
            .catch((error: any) => {
                console.error("Gate pass creation failed", error ?? "");
                Toast.error("Gate pass creation failed");
            })
            .finally(() => {
                this.mainFormRef.current?.resetFields();
                this.setState({ isGatePassCreateInProgress: false, isCreatingNewUser: false });
            });
    };

    private onGatePassFormClose = () => {
        this.mainFormRef.current?.resetFields();
        this.props.onFormClose();
    };

    private onTypeChange = (value: any) => {
        this.setState({ selectedGatePassType: value, isCheckoutDateSelected: false });
        this.mainFormRef.current?.resetFields(["checkOutDate", "returnDate"]);
    };

    private openNewItemModal = () => {
        this.setState({ isNewItemModalVisible: true });
    };

    private onNewItemFormClose = () => {
        this.itemsFormRef.current?.resetFields();
        this.setState({ isNewItemModalVisible: false });
    };

    private handleAddNewItem = (values: any) => {
        const { selectedGatePassItems, allItems, isAutoCompleteItemSelected } = this.state;

        if (!isAutoCompleteItemSelected) {
            if (!values || !values?.description || !values?.uom || !values?.qty) {
                Toast.error("Missing form fields", "Please fill all the required fields");
                console.error("Missing form fields");
                return;
            }

            this.setState({ isItemCreateInProgress: true });

            ItemManagerService.getInstance()
                .createItem({
                    itemName: values?.description,
                    serialNumber: values?.serialNo ?? "",
                    measurementUnit: values?.uom,
                })
                .then((itemCode: string) => {
                    Toast.success("Item created successfully");

                    const currentItem = {
                        itemCode,
                        itemName: values?.description,
                        serialNumber: values?.serialNo ?? "",
                        measurementUnit: values?.uom,
                        quantity: values?.qty,
                    };

                    this.itemsFormRef.current?.resetFields();

                    this.setState({
                        selectedGatePassItems: [...selectedGatePassItems, currentItem],
                        isNewItemModalVisible: false,
                        isAutoCompleteItemSelected: false,
                        isItemCreateInProgress: false
                    });

                    return;
                })
                .catch((error: any) => {
                    console.error("Item creation failed", error ?? "");
                    Toast.error("Item creation failed");
                    this.setState({ isItemCreateInProgress: false });
                });
        }

        const selectedItem = allItems.find((item) => item.itemName === values.description);
        const currentItem = {
            itemCode: selectedItem.itemCode,
            itemName: values?.description,
            serialNumber: values?.serialNo ?? "",
            measurementUnit: values?.uom,
            quantity: values?.qty,
        };

        this.itemsFormRef.current?.resetFields();

        this.setState({
            selectedGatePassItems: [...selectedGatePassItems, currentItem],
            isNewItemModalVisible: false,
        });
    };

    private handleRemoveItem = (itemCode: string) => {
        const { selectedGatePassItems } = this.state;
        this.setState({
            selectedGatePassItems: selectedGatePassItems.filter((item) => item.itemCode !== itemCode),
        });
    };

    private handleItemDescriptionSelect = (value: string) => {
        const { allItems } = this.state;
        const selectedItem = allItems.find((item) => item.itemName === value);
        if (selectedItem) {
            this.itemsFormRef.current?.setFieldsValue({
                itemCode: selectedItem.itemCode,
                serialNo: selectedItem.serialNumber ?? "",
                uom: selectedItem.measurementUnit,
            });

            this.setState({ isAutoCompleteItemSelected: true });
        }
    };

    private handleItemDescriptionChange = (value: string) => {
        const { selectedGatePassItems } = this.state;
        if (!selectedGatePassItems.find((item) => item.itemName === value)) {
            this.setState({ isAutoCompleteItemSelected: false });
            this.itemsFormRef.current?.resetFields(["serialNo", "uom", "qty"]);
        }
    };

    private onNewHolder = () => {
        this.setState({ isCreatingNewUser: true });
    };

    private onResetToSelectHolder = () => {
        this.setState({ isCreatingNewUser: false });
    };

    private onCheckOutDateChange = (date: Dayjs | null) => {
        this.setState({ isCheckoutDateSelected: true, checkoutDate: date });
        this.mainFormRef.current?.resetFields(["returnDate"]);
    };

    private disabledReturnDate = (current: Dayjs | null) => {
        const { checkoutDate } = this.state;
        return current && checkoutDate ? current.isBefore(checkoutDate, 'day') : false;
    };

    private renderUOMOptions = () => {
        return getUnitOfMeasurements().sort((a, b) => a.label.localeCompare(b.label)).map((item) => {
            return <Select.Option value={item.value}>{item.label}</Select.Option>;
        });
    };

    private renderAddItemForm = (): React.ReactNode => {
        const { isAutoCompleteItemSelected, isItemCreateInProgress, itemsAutoCompleteOptions } = this.state;

        return (
            <Form ref={this.itemsFormRef} layout="vertical" onFinish={this.handleAddNewItem}>
                <Form.Item name="description" label="Description" required>
                    <AutoComplete
                        options={itemsAutoCompleteOptions}
                        onSelect={this.handleItemDescriptionSelect}
                        onChange={this.handleItemDescriptionChange}
                        filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                    />
                </Form.Item>

                <Form.Item name="serialNo" label="Serial No">
                    <Input disabled={isAutoCompleteItemSelected} />
                </Form.Item>

                <Form.Item name="uom" label="Unit of Measurement" rules={[{ required: true, message: "Unit of measurement is required" }]}>
                    <Select disabled={isAutoCompleteItemSelected}>
                        {this.renderUOMOptions()}
                    </Select>
                </Form.Item>

                <Form.Item name="qty" label="Quantity" rules={[{ required: true, message: "Quantity is required" }]}>
                    <InputNumber min={1} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ marginTop: 16 }} loading={isItemCreateInProgress} disabled={isItemCreateInProgress}>
                        {isAutoCompleteItemSelected ? "Add Item" : "Create & Add Item"}
                    </Button>
                    <Button type="default" style={{ marginLeft: 16 }} onClick={this.onNewItemFormClose}>
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    render(): React.ReactNode {
        const { isNewItemModalVisible, selectedGatePassItems, selectedGatePassType, allUsers, isGatePassCreateInProgress, isCreatingNewUser, isCheckoutDateSelected } = this.state;

        const holderUsers = allUsers
            .filter((user: any) => [UserRole.USER_LEVEL_1, UserRole.GUEST_USER].includes(user.role))
            .map((user: any) => ({ label: `${user.firstName} ${user.lastName}`, value: user.userId }));
        const approverUsers = allUsers.filter((user: any) => user.role === UserRole.USER_LEVEL_0).map((user: any) => ({ label: `${user.firstName} ${user.lastName}`, value: user.userId }));

        const itemsTableColumns = [
            { title: "Item Code", dataIndex: "itemCode", key: "itemCode" },
            { title: "Description", dataIndex: "itemName", key: "itemName" },
            { title: "Serial No", dataIndex: "serialNumber", key: "serialNumber" },
            {
                title: "Unit of Measurement",
                dataIndex: "measurementUnit",
                key: "measurementUnit",
                render: (value: any) => { return getUnitOfMeasurementDisplayValue(value); }
            },
            { title: "Quantity", dataIndex: "quantity", key: "quantity" },
            {
                title: "Action",
                key: "action",
                render: (_: any, record: { itemCode: string; }) => <MinusCircleOutlined onClick={() => this.handleRemoveItem(record.itemCode)} />,
            },
        ];

        return (
            <Form onFinish={this.onGatePassFormFinish} ref={this.mainFormRef} layout="vertical" className="new-gate-pass-form">
                <div className="gate-pass-form-row">
                    <Form.Item name="type" label="Gate Pass Type" rules={[{ required: true, message: "Gate pass type is required" }]}>
                        <Select className="gate-pass-type-selector" onChange={this.onTypeChange}>
                            <Select.Option value="RETURNABLE">Returnable</Select.Option>
                            <Select.Option value="NON_RETURNABLE">Non Returnable</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="checkOutDate" label="Check Out Date" rules={[{ required: true, message: "Check out date is required" }]}>
                        <DatePicker disabledDate={disablePastDates} onChange={this.onCheckOutDateChange} />
                    </Form.Item>

                    {selectedGatePassType === "RETURNABLE" && isCheckoutDateSelected && <Form.Item name="returnDate" label="Return Date" rules={[{ required: true, message: "Return date is required" }]}>
                        <DatePicker disabledDate={this.disabledReturnDate} />
                    </Form.Item>}

                    <Form.Item name="vehicleNo" label="Vehicle No">
                        <Input />
                    </Form.Item>
                </div>

                <Form.Item name="location" label="Location" rules={[{ required: true, message: "Location is required" }]}>
                    <Input />
                </Form.Item>

                <Form.Item name="reason" label="Reason" rules={[{ required: true, message: "Reason is required" }]}>
                    <TextArea rows={2} maxLength={200} showCount />
                </Form.Item>

                {!isCreatingNewUser && <Form.Item name="gatePassHolder" label="Gate Pass Holder" rules={[{ required: true, message: "Gate pass holder is required" }]}>
                    <Select showSearch optionFilterProp="label" options={holderUsers} />
                </Form.Item>}

                {isCreatingNewUser && <div className="gate-pass-form-row">
                    <Form.Item name="newGatePassHolderFirstName" label="New Gate Pass Holder First Name" rules={[{ required: true, message: 'First name is required' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="newGatePassHolderLastName" label="New Gate Pass Holder Last Name" rules={[{ required: true, message: 'Last name is required' }]}>
                        <Input />
                    </Form.Item>
                </div>}

                {!isCreatingNewUser && <Button className="holder-action-btn" type="dashed" onClick={this.onNewHolder} icon={<PlusOutlined />}>
                    Create New Holder
                </Button>}

                {isCreatingNewUser && <Button className="holder-action-btn" type="dashed" onClick={this.onResetToSelectHolder} icon={<RollbackOutlined />}>
                    Select an Existing Holder
                </Button>}

                <Form.Item name="gatePassApprover" label="Gate Pass Approver" rules={[{ required: true, message: "Gate pass approver is required" }]}>
                    <Select showSearch optionFilterProp="label" options={approverUsers} />
                </Form.Item>

                <Divider></Divider>

                <Button type="dashed" onClick={this.openNewItemModal} icon={<PlusOutlined />}>
                    Add Item
                </Button>

                <Table className="items-table"
                    dataSource={selectedGatePassItems}
                    columns={itemsTableColumns}
                    rowKey="itemCode"
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                    bordered
                />

                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ marginTop: 16 }} loading={isGatePassCreateInProgress} disabled={isGatePassCreateInProgress}>
                        Submit
                    </Button>
                    <Button type="default" style={{ marginLeft: 16 }} onClick={this.onGatePassFormClose} disabled={isGatePassCreateInProgress}>
                        Cancel
                    </Button>
                </Form.Item>

                <GenericModal title="Add New Item" open={isNewItemModalVisible} onCancel={this.onNewItemFormClose} width={500}>
                    {this.renderAddItemForm()}
                </GenericModal>
            </Form>
        );
    }
}