import { CheckOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, InputNumber, Table, TableColumnsType, Tooltip } from "antd";
import React from "react";
import { GatePassItem, GatePassItemReceipt, GatePassManagerService } from "../../services/common/GatePassManagerService";
import { getUnitOfMeasurementDisplayValue } from '../../utils/utils';
import { GenericModal } from "../GenericModal/GenericModal";
import { Toast } from "../Toast/Toast";
import './GatePassItemsTable.scss';

interface GatePassItemsTableProps {
    gatePassNo: string;
    gatePassType: string;
    view?: string;
}

interface GatePassItemsTableState {
    currentGatePassItemData: GatePassItem[];
    isGatePassItemsLoading: boolean;
    currentItem: GatePassItem | undefined;
    showMarkItemDialog: boolean;
    receiveInProgress: boolean;
    hasReturnableItems: boolean;
}

export class GatePassItemsTable extends React.Component<GatePassItemsTableProps, GatePassItemsTableState> {
    private itemsTableColumns: TableColumnsType<GatePassItem>;
    private formRef: React.RefObject<FormInstance>;

    constructor(props: GatePassItemsTableProps) {
        super(props);

        this.state = {
            currentGatePassItemData: [],
            isGatePassItemsLoading: false,
            currentItem: undefined,
            showMarkItemDialog: false,
            receiveInProgress: false,
            hasReturnableItems: false
        };

        this.formRef = React.createRef();

        this.itemsTableColumns = [
            { title: 'Item Code', dataIndex: 'itemCode', key: 'itemCode' },
            { title: 'Description', dataIndex: 'itemName', key: 'itemName' },
            { title: 'Serial No', dataIndex: 'serialNumber', key: 'serialNumber' },
            {
                title: 'Unit of Measurement',
                dataIndex: 'measurementUnit',
                key: 'measurementUnit',
                render: (value) => { return getUnitOfMeasurementDisplayValue(value); }
            },
            { title: 'Total Quantity', dataIndex: 'totalQuantity', key: 'totalQuantity' },
            {
                title: 'Returnable Quantity',
                dataIndex: 'remainingQuantity',
                key: 'remainingQuantity',
                render: (value) => { return this.props.gatePassType === "Returnable" ? value : "-"; },
            }
        ];

        // TODO: check user role permissions as well
        if (this.props.view === "PENDING_RETURNS") {
            this.itemsTableColumns.push(
                {
                    title: 'Action',
                    key: 'action',
                    render: (text, record) => (
                        <Tooltip title={record.remainingQuantity === 0 ? "Fully Received" : "Record as Received"}>
                            <Button type="primary" shape="circle" size="small" icon={<CheckOutlined />}
                                onClick={() => this.onMarkReceived(record)} disabled={record.remainingQuantity === 0 || this.state.receiveInProgress} />
                        </Tooltip>
                    ),
                }
            );
        }
    }

    componentDidMount(): void {
        this.getGatePassItemData(this.props.gatePassNo);
    }

    componentDidUpdate(prevProps: Readonly<GatePassItemsTableProps>): void {
        if (this.props.gatePassNo !== prevProps.gatePassNo) {
            this.getGatePassItemData(this.props.gatePassNo);
        }
    }

    private getGatePassItemData = (gatePassNo: string) => {
        this.setState({ currentGatePassItemData: [], isGatePassItemsLoading: true });
        GatePassManagerService.getInstance().getItemsOfTheGatePass(gatePassNo).then((data: GatePassItem[]) => {
            let hasReturnableItems = false;
            for (let item of data) {
                if (item.remainingQuantity > 0) {
                    hasReturnableItems = true;
                    break;
                }
            }
            this.setState({ currentGatePassItemData: data, isGatePassItemsLoading: false, hasReturnableItems });
        }).catch((error: any) => {
            console.error("Error retrieving gate pass items", error ?? undefined);
            this.setState({ currentGatePassItemData: [], isGatePassItemsLoading: false, hasReturnableItems: false });
        });
    };

    private onMarkReceived = (data: any) => {
        this.setState({ showMarkItemDialog: true, currentItem: data });
    };

    private onReceiveConfirmed = (values: any) => {
        const { currentItem } = this.state;

        if (!values?.quantity || !currentItem || !currentItem.itemCode) {
            Toast.error("Error marking received items", "Please try again");
            return;
        }

        const markedItems: GatePassItemReceipt[] = [{
            quantity: values.quantity,
            itemCode: currentItem?.itemCode
        }];

        this.receiveItem(markedItems);
    };

    private onMarkReceivedCancel = () => {
        this.formRef.current?.resetFields();
        this.setState({ currentItem: undefined, showMarkItemDialog: false });
    };

    private receiveItem = (receivedItems: GatePassItemReceipt[]) => {
        const { gatePassNo } = this.props;
        this.setState({ receiveInProgress: true });

        GatePassManagerService.getInstance().handleReceptionOfGatePassItems(gatePassNo, receivedItems).then(() => {
            Toast.success("Received item recorded successfully");
            this.formRef.current?.resetFields();
            this.setState({ showMarkItemDialog: false, currentItem: undefined, receiveInProgress: false });
            this.getGatePassItemData(this.props.gatePassNo);
        }).catch((error: any) => {
            console.error("Error recording received item", error ?? undefined);
            Toast.error("Error recording received item");
            this.setState({ showMarkItemDialog: false, currentItem: undefined, receiveInProgress: false });
        });
    };

    private receiveAllRemainingItems = () => {
        const { currentGatePassItemData, hasReturnableItems } = this.state;

        if (!hasReturnableItems) {
            Toast.error("Error recording received all items", "No returnable items");
            return;
        }

        let markedItems: GatePassItemReceipt[] = [];

        currentGatePassItemData.forEach((item: GatePassItem) => {
            if (item.remainingQuantity > 0) {
                markedItems.push({
                    quantity: item.remainingQuantity,
                    itemCode: item.itemCode
                });
            }
        });

        this.receiveItem(markedItems);
    };

    render(): React.ReactNode {
        const { currentGatePassItemData, isGatePassItemsLoading, showMarkItemDialog, currentItem, receiveInProgress, hasReturnableItems } = this.state;
        const { view } = this.props;

        return <div className='gate-pass-items-table-container'>
            <Table className='gate-pass-items-table'
                dataSource={currentGatePassItemData}
                columns={this.itemsTableColumns}
                rowKey="itemCode"
                pagination={false}
                loading={isGatePassItemsLoading}
                scroll={{ x: 'max-content' }}
                bordered
            />

            {view === "PENDING_RETURNS" && hasReturnableItems &&
                <Button icon={<CheckOutlined />}
                    type="primary" className="receive-all-btn"
                    loading={receiveInProgress}
                    disabled={receiveInProgress}
                    onClick={this.receiveAllRemainingItems}>
                    Received All Items
                </Button>}

            <GenericModal title={`Received ${currentItem?.itemName}?`} open={showMarkItemDialog} onCancel={this.onMarkReceivedCancel} width={300}>
                <Form
                    ref={this.formRef}
                    layout="vertical"
                    className='mark-received-item-form'
                    onFinish={this.onReceiveConfirmed}
                >
                    <Form.Item name="quantity" label="Received Quantity" rules={[{ required: true, message: "Received quantity is required" }]}>
                        <InputNumber min={1} max={currentItem?.remainingQuantity} />
                    </Form.Item>

                    <Form.Item className="action-container">
                        <Button htmlType="submit" icon={<CheckOutlined />} type="primary" className="receive-btn" loading={receiveInProgress} disabled={receiveInProgress}>
                            Received
                        </Button>

                        <Button type="default" className="cancel-btn" onClick={this.onMarkReceivedCancel}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            </GenericModal>
        </div>;
    }
}