import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Subscription } from "rxjs";
import { CreateGatePassForm } from "../../components/GatePassForms/CreateGatePassForm/CreateGatePassForm";
import { EditGatePassForm } from "../../components/GatePassForms/EditGatePassForm/EditGatePassForm";
import { ViewGatePassForm } from "../../components/GatePassForms/ViewGatePassForm/ViewGatePassForm";
import { GenericGatePassTable } from "../../components/GenericGatePassTable/GenericGatePassTable";
import { GenericModal } from "../../components/GenericModal/GenericModal";
import { ParsedGatePassData, UserRole } from "../../defs/common";
import { AuthenticationService, AuthUser } from "../../services/common/AuthenticationService";
import { GatePassManagerService } from "../../services/common/GatePassManagerService";
import { exportToExcel, formatDate, getGatePassTypeDisplayValue, getUserNameById } from "../../utils/utils";
import "./GatePassesPage.scss";

interface GatePassesPageProps { }

interface GatePassesPageState {
    gatePassRows: any[];
    displayedGatePassRows: ParsedGatePassData[];
    gatePassDataLoading: boolean;
    showNewGatePassDialog: boolean;
    showViewGatePassDialog: boolean;
    showEditGatePassDialog: boolean;
    currentUser: AuthUser | null;
    selectedRow: any;
    currentGatePassId: string;
}

export class GatePassesPage extends React.Component<GatePassesPageProps, GatePassesPageState> {

    private gatePassDataSubscription?: Subscription;
    private isInitialized: boolean;

    constructor(props: GatePassesPageProps) {
        super(props);

        this.isInitialized = false;
        this.state = {
            gatePassRows: [],
            displayedGatePassRows: [],
            gatePassDataLoading: false,
            showNewGatePassDialog: false,
            showViewGatePassDialog: false,
            showEditGatePassDialog: false,
            currentUser: null,
            selectedRow: {},
            currentGatePassId: "",
        };
    }

    private initGatePassDataSubscription = (): void => {
        this.setState({ gatePassDataLoading: true });

        this.gatePassDataSubscription = GatePassManagerService.getInstance().getGatePassDataObservable().subscribe(gatePassData => {

            if (!this.isInitialized) {
                this.setState({ gatePassDataLoading: false });
            }

            this.setState({ gatePassRows: gatePassData });
            this.isInitialized = true;

        });

    };

    componentDidMount(): void {
        this.setCurrentUser();
        this.initGatePassDataSubscription();
    }

    componentWillUnmount(): void {
        this.gatePassDataSubscription?.unsubscribe();
    }

    private setCurrentUser = () => {
        this.setState({ currentUser: AuthenticationService.getInstance().getCurrentUser() });
    };

    private onGatePassView = (data: any) => {
        const fullData = {
            ...data,
            type: getGatePassTypeDisplayValue(data.type),
            approverName: getUserNameById(data.approverUserId),
            holderName: getUserNameById(data.holderUserId),
            checkOutDate: formatDate(data.checkoutDate),
            returnDate: formatDate(data.returnDate)
        };

        this.setState({ showViewGatePassDialog: true, selectedRow: fullData, currentGatePassId: data.id });
    };

    private onNewGatePassFormClose = () => {
        this.setState({ showNewGatePassDialog: false });
    };

    private onGatePassCreateButtonClicked = () => {
        this.setState({ showNewGatePassDialog: true });
    };

    private onGatePassEdit = (data: any) => {
        const fullData = {
            ...data,
            type: data.type,
            approverName: getUserNameById(data.approverUserId),
            holderName: getUserNameById(data.holderUserId),
            checkOutDate: formatDate(data.checkoutDate),
            returnDate: formatDate(data.returnDate)
        };

        this.setState({ showEditGatePassDialog: true, selectedRow: fullData, currentGatePassId: data.id });
    };

    private closeEditGatePassModal = (): void => {
        this.setState({ showEditGatePassDialog: false, selectedRow: undefined, currentGatePassId: "" });
    };

    private updateDisplayedData = (data: ParsedGatePassData[]) => {
        this.setState({ displayedGatePassRows: data });
    };

    private onExport = () => {
        const { displayedGatePassRows } = this.state;
        const customHeaders = {
            gatePassNo: "Gate Pass No",
            createdByUser: "Creator",
            approverUser: "Approver",
            holderUser: "Holder",
            type: "Type",
            checkoutDate: "Checkout Date",
            returnDate: "Return Date",
            location: "Location",
            reason: "Reason",
            vehicleNo: "Vehicle No",
            status: "Status",
            createdAt: "Created At"
        };
        exportToExcel(displayedGatePassRows, "open-gate-passes", customHeaders);
    };

    render(): React.ReactNode {
        const { gatePassRows, showNewGatePassDialog, showViewGatePassDialog, showEditGatePassDialog, currentUser, currentGatePassId, selectedRow } = this.state;

        const isGatePassCreationAllowed: boolean = currentUser !== null && [UserRole.ADMIN, UserRole.USER_LEVEL_1, UserRole.USER_LEVEL_0].includes(currentUser.role);
        const isGatePassEditAllowed: boolean = currentUser !== null && currentUser.role === UserRole.ADMIN;
        const isGatePassDeleteAllowed: boolean = currentUser !== null && currentUser.role === UserRole.ADMIN;

        return (
            <div className="gate-pass-page-container">
                <div className="header">
                    <div className="title">Open Gate Passes</div>

                    <div className="action-container">
                        {isGatePassCreationAllowed && <Button type="primary" icon={<PlusOutlined />} onClick={this.onGatePassCreateButtonClicked}>Create New Gate Pass</Button>}
                        <Button className="export-btn" type="primary" icon={<FileExcelOutlined />} onClick={() => this.onExport()}>Export</Button>
                    </div>
                </div>

                <div className="body">
                    <div className="gate-pass-table-container">
                        <GenericGatePassTable
                            gatePassRows={gatePassRows}
                            isEditAllowed={isGatePassEditAllowed}
                            isDeleteAllowed={isGatePassDeleteAllowed}
                            onGatePassView={this.onGatePassView}
                            onGatePassEdit={this.onGatePassEdit}
                            onDisplayDataChange={this.updateDisplayedData}
                        />

                        <GenericModal title="Gate Pass" open={showViewGatePassDialog} onCancel={() => this.setState({ showViewGatePassDialog: false })} width={700}>
                            <ViewGatePassForm gatePassNo={currentGatePassId} onFormClose={() => this.setState({ showViewGatePassDialog: false })} gatePassData={selectedRow} />
                        </GenericModal>
                    </div>
                </div>

                <GenericModal title="Create New Gate Pass" open={showNewGatePassDialog} onCancel={this.onNewGatePassFormClose} width={900}>
                    <CreateGatePassForm onFormFinish={this.onNewGatePassFormClose} onFormClose={this.onNewGatePassFormClose} />
                </GenericModal>

                <GenericModal title={`Edit Gate Pass: ${currentGatePassId}`} open={showEditGatePassDialog} onCancel={this.closeEditGatePassModal} width={800}>
                    <EditGatePassForm gatePassNo={currentGatePassId ?? ""} currentData={{ ...selectedRow }} onClose={this.closeEditGatePassModal} />
                </GenericModal>
            </div>
        );
    }
}