import { FileExcelOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Subscription } from "rxjs";
import { ViewGatePassForm } from "../../components/GatePassForms/ViewGatePassForm/ViewGatePassForm";
import { GenericGatePassTable } from "../../components/GenericGatePassTable/GenericGatePassTable";
import { GenericModal } from "../../components/GenericModal/GenericModal";
import { ParsedGatePassData } from "../../defs/common";
import { GatePassManagerService } from "../../services/common/GatePassManagerService";
import { exportToExcel, formatDate, getGatePassTypeDisplayValue, getUserNameById } from "../../utils/utils";
import "./InactiveGatePassPage.scss";

interface InactiveGatePassPageProps { }

interface InactiveGatePassPageState {
    gatePassRows: any[];
    displayedGatePassRows: ParsedGatePassData[];
    gatePassDataLoading: boolean;
    showViewGatePassDialog: boolean;
    selectedRow: any;
    currentGatePassId: string;
}

export class InactiveGatePassPage extends React.Component<InactiveGatePassPageProps, InactiveGatePassPageState> {
    private inactiveGatePassDataSubscription?: Subscription;

    constructor(props: InactiveGatePassPageProps) {
        super(props);

        this.state = {
            gatePassRows: [],
            displayedGatePassRows: [],
            gatePassDataLoading: false,
            showViewGatePassDialog: false,
            selectedRow: {},
            currentGatePassId: ""
        };
    }

    componentDidMount(): void {
        GatePassManagerService.getInstance().getInactiveGatePassDataObservable().then((observable) => {
            this.inactiveGatePassDataSubscription = observable.subscribe((data) => {
                this.setState({ gatePassRows: data });
            });
        });
    }

    componentWillUnmount(): void {
        this.inactiveGatePassDataSubscription?.unsubscribe();
    }

    private onGatePassView = (data: any) => {
        const fullData = {
            ...data,
            type: getGatePassTypeDisplayValue(data.type),
            approverName: getUserNameById(data.approverUserId),
            holderName: getUserNameById(data.holderUserId),
            checkOutDate: formatDate(data.checkoutDate),
            returnDate: formatDate(data.returnDate)
        };

        this.setState({ showViewGatePassDialog: true, selectedRow: fullData, currentGatePassId: data.id });
    };

    private updateDisplayedData = (data: ParsedGatePassData[]) => {
        this.setState({ displayedGatePassRows: data });
    };

    private onExport = () => {
        const { displayedGatePassRows } = this.state;
        const customHeaders = {
            gatePassNo: "Gate Pass No",
            createdByUser: "Creator",
            approverUser: "Approver",
            holderUser: "Holder",
            type: "Type",
            checkoutDate: "Checkout Date",
            returnDate: "Return Date",
            location: "Location",
            reason: "Reason",
            vehicleNo: "Vehicle No",
            status: "Status",
            createdAt: "Created At"
        };
        exportToExcel(displayedGatePassRows, "closed-gate-passes", customHeaders);
    };

    render(): React.ReactNode {
        const { gatePassRows, showViewGatePassDialog, currentGatePassId, selectedRow } = this.state;

        return <div className="inactive-gate-pass-page-container">
            <div className="header">
                <div className="title">Closed Gate Passes</div>

                <div className="action-container">
                    <Button className="export-btn" type="primary" icon={<FileExcelOutlined />} onClick={() => this.onExport()}>Export</Button>
                </div>
            </div>

            <div className="body">
                <div className="gate-pass-table-container">
                    <GenericGatePassTable
                        gatePassRows={gatePassRows}
                        onGatePassView={this.onGatePassView}
                        onDisplayDataChange={this.updateDisplayedData}
                    />

                    <GenericModal title="Gate Pass" open={showViewGatePassDialog} onCancel={() => this.setState({ showViewGatePassDialog: false })} width={700}>
                        <ViewGatePassForm gatePassNo={currentGatePassId} onFormClose={() => this.setState({ showViewGatePassDialog: false })} gatePassData={selectedRow} />
                    </GenericModal>
                </div>
            </div>
        </div>;
    }
}