import { FC } from "react";
import errorImage from "./assets/images/error-v1.jpg";
import { Button } from "antd";
import "./ErrorPage.scss";

interface ErrorPageProps { }

export const ErrorPage: FC<ErrorPageProps> = (): JSX.Element => {

    const refreshPage = (): void => {
        window.location.reload();
    }

    return (
        <div className="error-page">
            <div className="title">
                Sorry! An Application Error Occurred.
            </div>
            <div className="message">
                The application has run into an error. Sorry for the Inconvenience caused.
            </div>
            <div className="image">
                <img
                    src={errorImage}
                    alt="An error"
                    width={500}
                />
            </div>
            <div className="actions">
                <Button
                    type="primary"
                    onClick={refreshPage}
                >
                    Refresh
                </Button>
            </div>

            <div className="attributions">
                <a href="https://www.freepik.com/free-vector/tiny-people-examining-operating-system-error-warning-web-page-isolated-flat-illustration_11235921.htm#fromView=keyword&page=1&position=5&uuid=d2553cde-4f01-47b6-964b-adc629c84b0c">Image by pch.vector on Freepik</a>
            </div>
        </div>
    );
}