import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input } from "antd";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../components/Toast/Toast";
import { AuthenticationService, AuthUser } from "../../services/common/AuthenticationService";
import "./LoginPage.scss";

export const LoginPageWrapper: React.FC = () => {
    const navigate = useNavigate();
    return <LoginPage navigate={navigate} />;
};

interface LoginPageProps {
    navigate: (path: string) => void;
}

interface LoginPageState {
    isInProgress: boolean;
    action: Action;
    timeInSeconds: number;
    isLocked: boolean;
}

type Action = "LOGIN" | "RESET_PASSWORD";

class LoginPage extends React.Component<LoginPageProps, LoginPageState> {
    private readonly PASSWORD_RESET_LOCK_LOCK_TIME_IN_MS: number = 20000;
    private readonly UPDATE_TICK_IN_MS: number = 20000;
    private passwordResetLockTimer?: NodeJS.Timer;

    constructor(props: LoginPageProps) {
        super(props);

        this.state = {
            isInProgress: false,
            action: "LOGIN",
            timeInSeconds: 0,
            isLocked: false,
        };
    }

    private changeAction = (action: Action): void => {
        this.setState({ action });
    };

    private sendPasswordResetEmail = async (email: string): Promise<void> => {
        try {
            await AuthenticationService.getInstance().resetPassword(email);

            Toast.error("Rset Password", "An email is sent your email to reset your password");
        } catch (error: any) {
            Toast.error("Rset Password", "Please fill all the required fields");
        }
    };

    private updateCountDownForPasswordResetReTrying = (): void => {
        const { timeInSeconds: prevTimeInSeconds } = this.state;

        if (prevTimeInSeconds === 0) {
            clearInterval(this.passwordResetLockTimer);
            this.setState({ isLocked: false, timeInSeconds: this.PASSWORD_RESET_LOCK_LOCK_TIME_IN_MS });
            return;
        }

        this.setState({ timeInSeconds: prevTimeInSeconds - 1 });
    };

    private onFinish = async (values: any): Promise<void> => {
        const email = values?.email;
        const password = values?.password;

        if (!email || !password) {
            Toast.error("Login Failed", "Please fill all the required fields");
            return;
        }

        this.setState({ isInProgress: true });

        await AuthenticationService.getInstance()
            .signIn(email, password)
            .then((user: AuthUser) => {
                Toast.success("User logged in successfully");
                this.props.navigate("/gate-passes");
            })
            .catch((error: any) => {
                Toast.error("Login Failed", "Please try again");
            })
            .finally(() => {
                this.setState({ isInProgress: false });
            });
    };

    private submitPasswordResetForm = async (values: any): Promise<void> => {
        try {
            const email = values?.email;

            if (!email) return;

            this.setState({ isLocked: true, timeInSeconds: this.PASSWORD_RESET_LOCK_LOCK_TIME_IN_MS, isInProgress: true });

            this.passwordResetLockTimer = setInterval(this.updateCountDownForPasswordResetReTrying, this.UPDATE_TICK_IN_MS);
            await AuthenticationService.getInstance().resetPassword(email);

            Toast.success("Reset Password", "An email is sent to your email to reset your password");
        } catch (error: any) {
            Toast.error("Reset Password", "Please enter your email");
        } finally {
            this.setState({ isInProgress: false });
        }
    };

    private getLoginCardHeader = (): ReactNode => {
        const { action } = this.state;

        return (
            <div className="login-card-title">
                <div className="heading">Yugadanavi Gate Pass System</div>
                <div className="sub-heading">
                    {action === "LOGIN" && "Log In"}
                    {action === "RESET_PASSWORD" && "Reset Password"}
                </div>
            </div>
        );
    };

    private getLoginActionComponent = (): ReactNode => {
        const { isInProgress } = this.state;

        return (
            <Form name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={this.onFinish}>
                <Form.Item name="email" rules={[{ required: true, message: "Please input your Email!" }]}>
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                </Form.Item>

                <Form.Item name="password" rules={[{ required: true, message: "Please input your Password!" }]}>
                    <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" loading={isInProgress}>
                        Log in
                    </Button>
                </Form.Item>

                <Button type="text" onClick={() => this.changeAction("RESET_PASSWORD")} className="password-reset-form-button" disabled={isInProgress}>
                    Reset Your Password
                </Button>
            </Form>
        );
    };

    private getResetPasswordComponent = (): ReactNode => {
        const { isInProgress, isLocked, timeInSeconds } = this.state;

        return (
            <Form name="password_reset" className="password-reset-form" onFinish={this.submitPasswordResetForm}>
                <Form.Item name="email" rules={[{ required: true, message: "Please input your Email!" }]}>
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" loading={isLocked} disabled={isLocked}>
                        {isLocked ? `Retry in ${timeInSeconds}S` : "Send Password Reset Email"}
                    </Button>
                </Form.Item>

                <Button type="text" onClick={() => this.changeAction("LOGIN")} className="login-form-button" disabled={isInProgress}>
                    Go to Login
                </Button>
            </Form>
        );
    };

    render(): React.ReactNode {
        const { action } = this.state;
        return (
            <div className="login-page">
                <Card title={this.getLoginCardHeader()} style={{ width: 400 }}>
                    {action === "LOGIN" && this.getLoginActionComponent()}
                    {action === "RESET_PASSWORD" && this.getResetPasswordComponent()}
                </Card>
            </div>
        );
    }
}
