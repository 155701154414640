import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { ServiceInitializationStatus, UserRole } from "./../defs/common";
import { UserManagerService } from "./admin/UserManagerService";
import { ItemManagerService } from "./admin/ItemManagerService";
import { GatePassManagerService } from "./common/GatePassManagerService";
import { AuthenticationService } from "./common/AuthenticationService";
import { FirebaseService } from "./common/FirebaseService";

interface Service {
    init: Function,
    status: ServiceInitializationStatus,
    subscription: Subscription;
}

interface ServiceInitilizationSummary {
    [serviceId: string]: ServiceInitializationStatus;
}

export interface ServiceInitializationEvent {
    status: "DONE" | "FAILED" | "IN_PROGRESS",
    summary?: ServiceInitilizationSummary;
}


export class ServiceInitializer {
    private static readonly POLLING_TIME_INTERVAL_IN_MS: number = 800;
    private static readonly MAX_WAITING_TIME_IN_MS: number = 10000;
    private static services: Map<string, Service> = new Map();
    private static serviceInitializationEvent: BehaviorSubject<ServiceInitializationEvent> = new BehaviorSubject<ServiceInitializationEvent>({ status: "IN_PROGRESS" });
    private static timeIntervalRef?: NodeJS.Timer;
    private static timeoutRef?: NodeJS.Timer;
    private static isTimerInitialized: boolean = false;
    private static currentServiceInitializationStatus: ServiceInitializationEvent = { status: "IN_PROGRESS", summary: {} };


    public static initializeServices(): void {
        this.initializeCoreServices();
        this.initializeCommon();
    }

    public static getServiceInitializationStatusObservable = (): Observable<ServiceInitializationEvent> => {
        return this.serviceInitializationEvent.asObservable();
    };

    private static initializeCoreServices(): void {

    }

    private static initializeCommon(): void {
        FirebaseService.getInstance().init();
    }

    public static runServiceInitManager = (role: UserRole): void => {
        this.services.clear();

        switch (role) {
            case UserRole.ADMIN:
                this.services = this.getAdminServices();
                break;
            case UserRole.SECURITY_OFFICER:
                this.services = this.getSecurityOfficerServices();
                break;
            case UserRole.TOOL_ROOM_OFFICER:
                this.services = this.getToolRoomOfficerServices();
                break;
            case UserRole.USER_LEVEL_0:
                this.services = this.getUserLevel0Services();
                break;
            case UserRole.USER_LEVEL_1:
                this.services = this.getUserLevel1Services();
                break;
            default:
                throw new Error("Unknown user role");
        }

        const serviceIds: string[] = Array.from(this.services.keys());

        for (const serviceId of serviceIds) {

            const service: Service | undefined = this.services.get(serviceId);

            if (!service) {
                continue;
            }

            service.init();
            service.status = "IN_PROGRESS";
        }

        this.isTimerInitialized = true;
        this.timeIntervalRef = setInterval(this.checkStatus, this.POLLING_TIME_INTERVAL_IN_MS);
        this.timeoutRef = setInterval(this.publishServiceInitialzationStatus, this.MAX_WAITING_TIME_IN_MS);
    }

    private static checkStatus = (): void => {

        if (!this.isTimerInitialized) return;

        const serviceIds: string[] = Array.from(this.services.keys());
        const summary: { [serviceId: string]: ServiceInitializationStatus; } = {};
        let isAllDone: boolean = true;

        for (const serviceId of serviceIds) {

            const service: Service | undefined = this.services.get(serviceId);
            summary[serviceId] = "NOT_INITIALIZED";

            if (!service) {
                summary[serviceId] = "ERROR";
                isAllDone = false;
                continue;
            }

            if (service.status !== "DONE") {
                isAllDone = false;
            }
            summary[serviceId] = service.status;
        }

        this.currentServiceInitializationStatus = { status: isAllDone ? "DONE" : "FAILED", summary };


        if (isAllDone) {
            clearInterval(this.timeIntervalRef);
            clearTimeout(this.timeoutRef);

            this.publishServiceInitialzationStatus();
        }

        // TODO: Log and Error handle
        console.log("Service initialization Summary:", { summary });


    };

    private static publishServiceInitialzationStatus = (): void => {
        this.serviceInitializationEvent.next(this.currentServiceInitializationStatus);
    };

    private static onServiceInitizationEvent = (serviceId: string, initializationStatus: ServiceInitializationStatus) => {

        const service: Service | undefined = this.services.get(serviceId);

        if (!service) {
            return;
        }

        service.status = initializationStatus;
        service.subscription.unsubscribe();




    };

    private static getAdminServices(): Map<string, Service> {
        const services: Map<string, Service> = new Map();
        services.set("authenticationService", { init: AuthenticationService.getInstance().init, status: "NOT_INITIALIZED", subscription: AuthenticationService.getInstance().getInitializationStatusObservable().subscribe(event => this.onServiceInitizationEvent("authenticationService", event.status)) });

        services.set("userManager", { init: UserManagerService.getInstance().init, status: "NOT_INITIALIZED", subscription: UserManagerService.getInstance().getInitializationStatusObservable().subscribe(event => this.onServiceInitizationEvent("userManager", event.status)) });
        services.set("itemsManager", { init: ItemManagerService.getInstance().init, status: "NOT_INITIALIZED", subscription: ItemManagerService.getInstance().getInitilizationStatuObservable().subscribe(event => this.onServiceInitizationEvent("itemsManager", event.status)) });
        services.set("gatePassManager", { init: GatePassManagerService.getInstance().init, status: "NOT_INITIALIZED", subscription: GatePassManagerService.getInstance().getInitializationStatusObservable().subscribe(event => this.onServiceInitizationEvent("gatePassManager", event.status)) });

        return services;
    }


    private static getSecurityOfficerServices(): Map<string, Service> {
        const services: Map<string, Service> = new Map();
        services.set("authenticationService", { init: AuthenticationService.getInstance().init, status: "NOT_INITIALIZED", subscription: AuthenticationService.getInstance().getInitializationStatusObservable().subscribe(event => this.onServiceInitizationEvent("authenticationService", event.status)) });

        services.set("userManager", { init: UserManagerService.getInstance().init, status: "NOT_INITIALIZED", subscription: UserManagerService.getInstance().getInitializationStatusObservable().subscribe(event => this.onServiceInitizationEvent("userManager", event.status)) });
        services.set("itemsManager", { init: ItemManagerService.getInstance().init, status: "NOT_INITIALIZED", subscription: ItemManagerService.getInstance().getInitilizationStatuObservable().subscribe(event => this.onServiceInitizationEvent("itemsManager", event.status)) });
        services.set("gatePassManager", { init: GatePassManagerService.getInstance().init, status: "NOT_INITIALIZED", subscription: GatePassManagerService.getInstance().getInitializationStatusObservable().subscribe(event => this.onServiceInitizationEvent("gatePassManager", event.status)) });

        return services;
    }

    private static getToolRoomOfficerServices(): Map<string, Service> {
        const services: Map<string, Service> = new Map();
        services.set("authenticationService", { init: AuthenticationService.getInstance().init, status: "NOT_INITIALIZED", subscription: AuthenticationService.getInstance().getInitializationStatusObservable().subscribe(event => this.onServiceInitizationEvent("authenticationService", event.status)) });

        services.set("userManager", { init: UserManagerService.getInstance().init, status: "NOT_INITIALIZED", subscription: UserManagerService.getInstance().getInitializationStatusObservable().subscribe(event => this.onServiceInitizationEvent("userManager", event.status)) });
        services.set("itemsManager", { init: ItemManagerService.getInstance().init, status: "NOT_INITIALIZED", subscription: ItemManagerService.getInstance().getInitilizationStatuObservable().subscribe(event => this.onServiceInitizationEvent("itemsManager", event.status)) });
        services.set("gatePassManager", { init: GatePassManagerService.getInstance().init, status: "NOT_INITIALIZED", subscription: GatePassManagerService.getInstance().getInitializationStatusObservable().subscribe(event => this.onServiceInitizationEvent("gatePassManager", event.status)) });

        return services;
    }

    private static getUserLevel0Services(): Map<string, Service> {
        const services: Map<string, Service> = new Map();
        services.set("authenticationService", { init: AuthenticationService.getInstance().init, status: "NOT_INITIALIZED", subscription: AuthenticationService.getInstance().getInitializationStatusObservable().subscribe(event => this.onServiceInitizationEvent("authenticationService", event.status)) });

        services.set("userManager", { init: UserManagerService.getInstance().init, status: "NOT_INITIALIZED", subscription: UserManagerService.getInstance().getInitializationStatusObservable().subscribe(event => this.onServiceInitizationEvent("userManager", event.status)) });
        services.set("itemsManager", { init: ItemManagerService.getInstance().init, status: "NOT_INITIALIZED", subscription: ItemManagerService.getInstance().getInitilizationStatuObservable().subscribe(event => this.onServiceInitizationEvent("itemsManager", event.status)) });
        services.set("gatePassManager", { init: GatePassManagerService.getInstance().init, status: "NOT_INITIALIZED", subscription: GatePassManagerService.getInstance().getInitializationStatusObservable().subscribe(event => this.onServiceInitizationEvent("gatePassManager", event.status)) });

        return services;
    }

    private static getUserLevel1Services(): Map<string, Service> {
        const services: Map<string, Service> = new Map();
        services.set("authenticationService", { init: AuthenticationService.getInstance().init, status: "NOT_INITIALIZED", subscription: AuthenticationService.getInstance().getInitializationStatusObservable().subscribe(event => this.onServiceInitizationEvent("authenticationService", event.status)) });

        services.set("userManager", { init: UserManagerService.getInstance().init, status: "NOT_INITIALIZED", subscription: UserManagerService.getInstance().getInitializationStatusObservable().subscribe(event => this.onServiceInitizationEvent("userManager", event.status)) });
        services.set("itemsManager", { init: ItemManagerService.getInstance().init, status: "NOT_INITIALIZED", subscription: ItemManagerService.getInstance().getInitilizationStatuObservable().subscribe(event => this.onServiceInitizationEvent("itemsManager", event.status)) });
        services.set("gatePassManager", { init: GatePassManagerService.getInstance().init, status: "NOT_INITIALIZED", subscription: GatePassManagerService.getInstance().getInitializationStatusObservable().subscribe(event => this.onServiceInitizationEvent("gatePassManager", event.status)) });

        return services;
    }


}