import { Input, Table, TableColumnsType } from "antd";
import React from "react";
import { GatePassItemHistoryRecord, GatePassItemHistoryResult, GatePassItemStatus, GatePassManagerService } from "../../services/common/GatePassManagerService";
import { formatDate, formatDateTimeEpoch, getGatePassItemStatusDisplayValue, getGatePassTypeDisplayValue, getUserNameById } from "../../utils/utils";
import './ItemHistoryView.scss';

const TextFilter: React.FC<{ setSelectedKeys: any; selectedKeys: any; confirm: () => void; }> = ({ setSelectedKeys, selectedKeys, confirm }) => (
    <Input
        placeholder="Search"
        value={selectedKeys[0] || ''}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={confirm}
        onBlur={confirm}
    />
);

export interface GatePassItemHistoryTableData {
    key: string;
    gatePassId: string;
    holderUserId: string;
    createdBy: string;
    approverUserId: string;
    type: string;
    checkoutDate: number;
    returnDate: number | null;
    location: string;
    reason: string;
    vehicleNo: string | null;
    status: GatePassItemStatus;
    createdAt: number;
    totalQuantity: number;
    remainingQuantity: number;
}

interface ItemHistoryViewProps {
    currentItemCode: string;
}

interface ItemHistoryViewState {
    itemHistoryData: any[];
    itemHistoryDataLading: boolean;
    isItemAvailable: boolean;
}

export class ItemHistoryView extends React.Component<ItemHistoryViewProps, ItemHistoryViewState> {
    constructor(props: ItemHistoryViewProps) {
        super(props);

        this.state = {
            itemHistoryData: [],
            itemHistoryDataLading: false,
            isItemAvailable: false
        };
    }

    componentDidMount(): void {
        this.getItemHistory();
    }

    componentDidUpdate(prevProps: Readonly<ItemHistoryViewProps>): void {
        if (this.props.currentItemCode !== prevProps.currentItemCode) {
            this.getItemHistory();
        }
    }

    private getItemHistory = () => {
        this.setState({ itemHistoryDataLading: true });
        GatePassManagerService.getInstance().queryItemHistoryByItemCode(this.props.currentItemCode).then((data: GatePassItemHistoryResult) => {
            let itemHistoryData: GatePassItemHistoryTableData[] = [];
            data?.history.forEach((item: GatePassItemHistoryRecord) => {
                itemHistoryData.push({
                    key: item.gatePassId,
                    gatePassId: item.gatePassId,
                    holderUserId: item.gatePass.holderUserId,
                    createdBy: item.createdBy,
                    approverUserId: item.gatePass.approverUserId,
                    type: item.gatePass.type,
                    checkoutDate: item.gatePass.checkoutDate,
                    returnDate: item.gatePass.returnDate,
                    location: item.gatePass.location,
                    reason: item.gatePass.reason,
                    vehicleNo: item.gatePass.vehicleNo,
                    status: item.status,
                    createdAt: item.createdAt,
                    totalQuantity: item.totalQuantity,
                    remainingQuantity: item.remainingQuantity
                });
            });
            itemHistoryData.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            this.setState({ itemHistoryData, isItemAvailable: data.isAvailable, itemHistoryDataLading: false });
        }).catch((error) => {
            console.error("Error loading item history data", error ?? "");
            this.setState({ itemHistoryData: [], isItemAvailable: false, itemHistoryDataLading: false });
        });
    };

    render(): React.ReactNode {
        const { itemHistoryData, itemHistoryDataLading } = this.state;

        const columns: TableColumnsType<GatePassItemHistoryTableData> = [
            {
                title: 'Gate Pass No',
                dataIndex: 'gatePassId',
                key: 'gatePassId',
                sorter: (a, b) => a.gatePassId.localeCompare(b.gatePassId),
                sortDirections: ['descend', 'ascend'],
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                    <TextFilter
                        setSelectedKeys={setSelectedKeys}
                        selectedKeys={selectedKeys}
                        confirm={confirm}
                    />
                ),
                onFilter: (value, record) => (record.gatePassId || '').toLowerCase().includes((value as string).toLowerCase())
            },
            {
                title: 'Creator',
                dataIndex: 'createdBy',
                key: 'createdBy',
                render: (value) => { return getUserNameById(value); },
                sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(itemHistoryData.map(row => getUserNameById(row.createdBy)))].map(name => ({ text: name, value: name })),
                onFilter: (value, record) => getUserNameById(record.createdBy).includes(value as string)
            },
            {
                title: 'Approver',
                dataIndex: 'approverUserId',
                key: 'approverUserId',
                render: (value) => { return getUserNameById(value); },
                sorter: (a, b) => a.approverUserId.localeCompare(b.approverUserId),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(itemHistoryData.map(row => getUserNameById(row.approverUserId)))].map(name => ({ text: name, value: name })),
                onFilter: (value, record) => getUserNameById(record.approverUserId).includes(value as string)
            },
            {
                title: 'Holder',
                dataIndex: 'holderUserId',
                key: 'holderUserId',
                render: (value) => { return getUserNameById(value); },
                sorter: (a, b) => a.holderUserId.localeCompare(b.holderUserId),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(this.state.itemHistoryData.map(row => getUserNameById(row.holderUserId)))].map(name => ({ text: name, value: name })),
                onFilter: (value, record) => getUserNameById(record.holderUserId).includes(value as string)
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                render: (value) => { return getGatePassTypeDisplayValue(value); },
                sorter: (a, b) => a.type.localeCompare(b.type),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(itemHistoryData.map(row => getGatePassTypeDisplayValue(row.type)))].map(type => ({ text: type, value: type })),
                onFilter: (value, record) => getGatePassTypeDisplayValue(record.type) === (value as string)
            },
            {
                title: 'Check Out Date',
                dataIndex: 'checkoutDate',
                key: 'checkoutDate',
                render: (value) => { return formatDate(value); },
                sorter: (a, b) => new Date(a.checkoutDate).getTime() - new Date(b.checkoutDate).getTime(),
                sortDirections: ['descend', 'ascend']
            },
            {
                title: 'Return Date',
                dataIndex: 'returnDate',
                key: 'returnDate',
                render: (value) => { return formatDate(value); },
                // sorter: (a, b) => new Date(a.returnDate).getTime() - new Date(b.returnDate).getTime(),
                // sortDirections: ['descend', 'ascend']
            },
            {
                title: 'Location',
                dataIndex: 'location',
                key: 'location',
                sorter: (a, b) => a.location.localeCompare(b.location),
                sortDirections: ['descend', 'ascend'],
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                    <TextFilter
                        setSelectedKeys={setSelectedKeys}
                        selectedKeys={selectedKeys}
                        confirm={confirm}
                    />
                ),
                onFilter: (value, record) => (record.location || '').toLowerCase().includes((value as string).toLowerCase())
            },
            {
                title: 'Reason',
                dataIndex: 'reason',
                key: 'reason',
                sorter: (a, b) => a.reason.localeCompare(b.reason),
                sortDirections: ['descend', 'ascend'],
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                    <TextFilter
                        setSelectedKeys={setSelectedKeys}
                        selectedKeys={selectedKeys}
                        confirm={confirm}
                    />
                ),
                onFilter: (value, record) => (record.reason || '').toLowerCase().includes((value as string).toLowerCase())
            },
            {
                title: 'Vehicle No',
                dataIndex: 'vehicleNo',
                key: 'vehicleNo',
                sorter: (a, b) => (a.vehicleNo || '').localeCompare(b.vehicleNo || ''),
                sortDirections: ['descend', 'ascend'],
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                    <TextFilter
                        setSelectedKeys={setSelectedKeys}
                        selectedKeys={selectedKeys}
                        confirm={confirm}
                    />
                ),
                onFilter: (value, record) => (record.vehicleNo || '').toLowerCase().includes((value as string).toLowerCase())
            },
            {
                title: 'Total Quantity',
                dataIndex: 'totalQuantity',
                key: 'totalQuantity',
                sorter: (a, b) => a.totalQuantity - b.totalQuantity,
                sortDirections: ['descend', 'ascend'],
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                    <TextFilter
                        setSelectedKeys={setSelectedKeys}
                        selectedKeys={selectedKeys}
                        confirm={confirm}
                    />
                ),
                onFilter: (value, record) => (record.totalQuantity || 0).toString().includes((value as string))
            },
            {
                title: 'Receivable Quantity',
                dataIndex: 'remainingQuantity',
                key: 'remainingQuantity',
                sorter: (a, b) => a.remainingQuantity - b.remainingQuantity,
                sortDirections: ['descend', 'ascend'],
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                    <TextFilter
                        setSelectedKeys={setSelectedKeys}
                        selectedKeys={selectedKeys}
                        confirm={confirm}
                    />
                ),
                onFilter: (value, record) => (record.remainingQuantity || 0).toString().includes((value as string))
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (value) => { return getGatePassItemStatusDisplayValue(value); },
                sorter: (a, b) => a.status.localeCompare(b.status),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(itemHistoryData.map(row => getGatePassItemStatusDisplayValue(row.status)))].map(status => ({ text: status, value: status })),
                onFilter: (value, record) => getGatePassItemStatusDisplayValue(record.status) === (value as string)
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (value) => { return formatDateTimeEpoch(value); },
                sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
                sortDirections: ['descend', 'ascend']
            },
        ];

        return (
            <div className="item-history-view">
                <Table columns={columns} dataSource={itemHistoryData} loading={itemHistoryDataLading} scroll={{ x: 'max-content' }} bordered />
            </div>
        );
    }
}