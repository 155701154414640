import { CheckCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, LockOutlined, UnlockOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { Button, Input, Table, TableColumnsType, Tag, Tooltip } from "antd";
import React from "react";
import { GenericConfirmationModal } from "../../../components/GenericConfirmationModal/GenericConfirmationModal";
import { Toast } from "../../../components/Toast/Toast";
import { UserRole } from "../../../defs/common";
import { UserManagerService } from "../../../services/admin/UserManagerService";
import { getUserRoleDisplayValue } from "../../../utils/utils";
import './UsersTable.scss';

const tableHeight = window.innerHeight - 200;

const TextFilter: React.FC<{ setSelectedKeys: any; selectedKeys: any; confirm: () => void; }> = ({ setSelectedKeys, selectedKeys, confirm }) => (
    <Input
        placeholder="Search"
        value={selectedKeys[0] || ''}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={confirm}
        onBlur={confirm}
    />
);

export interface UsersTableData {
    key: string;
    firstName: string;
    lastName: string;
    email: string;
    role: UserRole;
    isActive: boolean;
}

interface UsersTableProps {
    rows: UsersTableData[];
    isLoading: boolean;
    onActionComplete?: () => void;
}

interface UsersTableState {
    selectedRow?: any;
    isDeleteConfirmationModalVisible: boolean;
    isStatusChangeConfirmationModalVisible: boolean;
    isRoleUpgradeConfirmationModalVisible: boolean;
    isInProgress: boolean;
}

export class UsersTable extends React.Component<UsersTableProps, UsersTableState> {
    constructor(props: UsersTableProps) {
        super(props);

        this.state = {
            isDeleteConfirmationModalVisible: false,
            isStatusChangeConfirmationModalVisible: false,
            isRoleUpgradeConfirmationModalVisible: false,
            selectedRow: undefined,
            isInProgress: false
        };
    }

    private userStatusIndicatorChip = (isActive: boolean | null): JSX.Element => {
        return (
            <Tag className="user-status-indicator-chip"
                icon={isActive ? <CheckCircleOutlined /> : <ExclamationCircleOutlined />}
                color={isActive ? "success" : "error"}
            >
                {isActive ? "Active" : "Inactive"}
            </Tag>
        );
    };

    private showDeleteConfirmationModal = (selectedRow: any): void => {
        this.setState({ selectedRow, isDeleteConfirmationModalVisible: true });
    };

    private showStatusChangeConfirmationModal = (selectedRow: any): void => {
        this.setState({ selectedRow, isStatusChangeConfirmationModalVisible: true });
    };

    private showRoleUpgradeConfirmationModal = (selectedRow: any): void => {
        this.setState({ selectedRow, isRoleUpgradeConfirmationModalVisible: true });
    };

    private closeDeleteConfirmationModal = (): void => {
        this.setState({ selectedRow: undefined, isDeleteConfirmationModalVisible: false });
    };

    private closeStatusChangeConfirmationModal = (): void => {
        this.setState({ selectedRow: undefined, isStatusChangeConfirmationModalVisible: false });
    };

    private closeRoleUpgradeConfirmationModal = (): void => {
        this.setState({ selectedRow: undefined, isRoleUpgradeConfirmationModalVisible: false });
    };

    private deleteUser = async (): Promise<void> => {

        const { selectedRow } = this.state;
        const { onActionComplete } = this.props;

        if (!selectedRow) {
            Toast.error("Delete User", "User deletion is failed!");
            return;
        }

        this.setState({ isInProgress: true });

        try {
            await UserManagerService.getInstance().deleteUserByUserId(selectedRow.userId);
            onActionComplete?.();

            Toast.success("Delete User", "User deleted successfully!");
        } catch (error: any) {
            Toast.error("Delete User", error.message ?? "User deletion failed!");
        } finally {
            this.setState({ isInProgress: false });
            this.closeDeleteConfirmationModal();
        }
    };

    private changeActivationStatus = async (): Promise<void> => {

        const { selectedRow } = this.state;
        const { onActionComplete } = this.props;

        if (!selectedRow) {
            Toast.error("User Activation/Deactivation", "User activation/deactivation is failed!");
            return;
        }

        this.setState({ isInProgress: true });
        const activationStatus: "ACTIVATE" | "DEACTIVATE" = selectedRow.isActive ? "DEACTIVATE" : "ACTIVATE";

        try {
            await UserManagerService.getInstance().changeUserActivationStatus(selectedRow.userId, activationStatus);
            onActionComplete?.();

            Toast.success("User Activation/Deactivation", `User ${selectedRow.isActive ? "deactivated" : "activated"} successfully!`);
        } catch (error: any) {
            Toast.error("User Activation/Deactivation", error.message ?? "User activation/deactivation is failed!");
        } finally {
            this.setState({ isInProgress: false });
            this.closeStatusChangeConfirmationModal();
        }
    };

    private upgradeUserRole = async (): Promise<void> => {
        const { selectedRow } = this.state;
        const { onActionComplete } = this.props;

        if (!selectedRow) {
            Toast.error("User Role Upgrade", "User role upgrade is failed!");
            return;
        }

        this.setState({ isInProgress: true });

        try {
            await UserManagerService.getInstance().upgradeUserRole(selectedRow.userId, UserRole.USER_LEVEL_0);
            onActionComplete?.();

            Toast.success("User Role Upgrade", `User role upgraded successfully!`);
        } catch (error: any) {
            Toast.error("User Role Upgrade", error.message ?? "User role upgrade is failed!");
        } finally {
            this.setState({ isInProgress: false });
            this.closeRoleUpgradeConfirmationModal();
        }

    };

    private getStatusChangeButtonToolTipTitle = (rowData: any) => {
        const isGuestUser = rowData.role === UserRole.GUEST_USER;
        if (isGuestUser) {
            return "Guest users cannot be activated";
        }
        return rowData.isActive ? "Deactivate" : "Activate";
    };

    private getRowActions = (cellValue: any, rowData: any): JSX.Element => {
        const isStatusChangeDisabled = rowData.role === UserRole.GUEST_USER;
        const isRoleUpgradeDisabled = rowData.role !== UserRole.USER_LEVEL_1;

        return (
            <div className="row-actions">
                <Tooltip title={this.getStatusChangeButtonToolTipTitle(rowData)}>
                    <Button
                        className={isStatusChangeDisabled ? "" : "btn-change-status"}
                        type="primary"
                        shape="circle"
                        size="small"
                        icon={rowData.isActive ? <LockOutlined /> : <UnlockOutlined />}
                        disabled={isStatusChangeDisabled}
                        onClick={() => this.showStatusChangeConfirmationModal(rowData)}
                    />
                </Tooltip>

                <Tooltip title="Delete">
                    <Button
                        className="btn-delete-user"
                        type="primary"
                        shape="circle"
                        size="small"
                        icon={<DeleteOutlined />}
                        onClick={() => this.showDeleteConfirmationModal(rowData)}
                    />
                </Tooltip>

                {!isRoleUpgradeDisabled && <Tooltip title={isRoleUpgradeDisabled ? "Role upgrade not available" : "Role upgrade"}>
                    <Button
                        className={isRoleUpgradeDisabled ? "" : "btn-upgrade-user"}
                        type="primary"
                        shape="circle"
                        size="small"
                        icon={<UsergroupAddOutlined />}
                        disabled={isRoleUpgradeDisabled}
                        onClick={() => this.showRoleUpgradeConfirmationModal(rowData)}
                    />
                </Tooltip>}
            </div>
        );
    };

    render(): React.ReactNode {
        const { rows, isLoading } = this.props;
        const { isDeleteConfirmationModalVisible, selectedRow, isInProgress, isStatusChangeConfirmationModalVisible, isRoleUpgradeConfirmationModalVisible } = this.state;
        const deleteConfirmationMessage: string = `Do you want to DELETE the user: ${selectedRow?.firstName} ${selectedRow?.lastName}?`;
        const changeStatusConfirmationMessage: string = `Do you want to ${selectedRow?.isActive ? "Deactivate" : "Activate"} the user: ${selectedRow?.firstName} ${selectedRow?.lastName}?`;
        const roleUpgradeConfirmationMessage: string = `Do you want to upgrade ${selectedRow?.firstName} ${selectedRow?.lastName}'s user role to Approver?`;

        const columns: TableColumnsType<UsersTableData> = [
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
                width: 150,
                sorter: (a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(rows.map(row => row.firstName))].map(name => ({ text: name, value: name })),
                onFilter: (value, record) => record.firstName.includes(value as string),
                filterSearch: true
                // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                //     <TextFilter
                //         setSelectedKeys={setSelectedKeys}
                //         selectedKeys={selectedKeys}
                //         confirm={confirm}
                //     />
                // ),
                // onFilter: (value, record) => (record.firstName || '').toLowerCase().includes((value as string).toLowerCase())
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
                width: 150,
                sorter: (a, b) => a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(rows.map(row => row.lastName))].map(name => ({ text: name, value: name })),
                onFilter: (value, record) => record.lastName.includes(value as string),
                filterSearch: true
                // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                //     <TextFilter
                //         setSelectedKeys={setSelectedKeys}
                //         selectedKeys={selectedKeys}
                //         confirm={confirm}
                //     />
                // ),
                // onFilter: (value, record) => (record.lastName || '').toLowerCase().includes((value as string).toLowerCase())
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                width: 180,
                sorter: (a, b) => a.email.localeCompare(b.email),
                sortDirections: ['descend', 'ascend'],
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                    <TextFilter
                        setSelectedKeys={setSelectedKeys}
                        selectedKeys={selectedKeys}
                        confirm={confirm}
                    />
                ),
                onFilter: (value, record) => (record.email || '').toLowerCase().includes((value as string).toLowerCase())
            },
            {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
                width: 180,
                render: (value) => { return getUserRoleDisplayValue(value); },
                sorter: (a, b) => getUserRoleDisplayValue(a.role).localeCompare(getUserRoleDisplayValue(b.role)),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(rows.map(row => getUserRoleDisplayValue(row.role)))].map(type => ({ text: type, value: type })),
                onFilter: (value, record) => getUserRoleDisplayValue(record.role) === (value as string),
                filterSearch: true
            },
            {
                title: 'Status',
                dataIndex: 'isActive',
                key: 'isActive',
                width: 120,
                render: (value) => this.userStatusIndicatorChip(value),
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
                width: 120,
                fixed: 'right',
                render: this.getRowActions,
            },
        ];

        return (
            <React.Fragment>
                <Table
                    className="user-table"
                    columns={columns}
                    dataSource={rows}
                    loading={isLoading}
                    pagination={false} scroll={{ x: 'max-content', y: tableHeight }}
                    bordered
                />

                <GenericConfirmationModal
                    show={isDeleteConfirmationModalVisible}
                    title={"Delete Confirmation"}
                    description={deleteConfirmationMessage}
                    type="warning"
                    onCancel={this.closeDeleteConfirmationModal}
                    isLoading={isInProgress}
                    onConfirm={this.deleteUser}
                />

                <GenericConfirmationModal
                    show={isStatusChangeConfirmationModalVisible}
                    title={"Activate/Deactivate Confirmation"}
                    description={changeStatusConfirmationMessage}
                    type="warning"
                    onCancel={this.closeStatusChangeConfirmationModal}
                    isLoading={isInProgress}
                    onConfirm={this.changeActivationStatus}
                />

                <GenericConfirmationModal
                    show={isRoleUpgradeConfirmationModalVisible}
                    title={"User Role Upgrade"}
                    description={roleUpgradeConfirmationMessage}
                    type="info"
                    onCancel={this.closeRoleUpgradeConfirmationModal}
                    isLoading={isInProgress}
                    onConfirm={this.upgradeUserRole}
                />
            </React.Fragment>
        );
    }
}