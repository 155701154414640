import ReactDOM from "react-dom/client";
import { App } from "./App";
import { ServiceInitializer } from "./services/ServiceInitializer";
import { ErrorBoundary } from "./ErrorBoundary";
import { ErrorPage } from "./ErrorPage";
import "./index.css";

ServiceInitializer.initializeServices();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);


root.render(
    // <React.StrictMode>
    <ErrorBoundary fallback={<ErrorPage />} >
        <App />
    </ErrorBoundary>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
