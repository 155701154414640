import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, Select } from "antd";
import React from "react";
import { GenericModal } from "../../components/GenericModal/GenericModal";
import { Toast } from "../../components/Toast/Toast";
import { UserRole } from '../../defs/common';
import { FirebaseService } from "../../services/common/FirebaseService";
import { getUserRoles } from '../../utils/utils';
import { UsersTable, UsersTableData } from "../UsersPage/components/UsersTable";
import './UsersPage.scss';
import { UserManagerService } from '../../services/admin/UserManagerService';
import { Subscription } from 'rxjs';

interface UsersPageProps { }



interface UsersPageState {
    userRows: UsersTableData[];
    showNewUsersDialog: boolean;
    inProgress: boolean;
    isDataLoading: boolean;
    selectedUserRole: UserRole | undefined;
}

export class UsersPage extends React.Component<UsersPageProps, UsersPageState> {
    private formRef: React.RefObject<FormInstance>;
    private createUser = FirebaseService.getInstance().getHTTPSCallableFunction("createUser");
    private userDataSubscription?: Subscription;

    constructor(props: UsersPageProps) {
        super(props);
        this.state = {
            userRows: [],
            showNewUsersDialog: false,
            inProgress: false,
            isDataLoading: false,
            selectedUserRole: undefined
        };

        this.formRef = React.createRef();
    }

    componentDidMount(): void {
        this.userDataSubscription = UserManagerService.getInstance().getUserDataObservable().subscribe(userData => {
            const filteredUsers: any[] = userData.filter(user => !user.isDeleted).sort((a, b) => a.firstName.localeCompare(b.firstName));
            this.setState({ userRows: filteredUsers });
        });
    }

    componentWillUnmount(): void {
        this.userDataSubscription?.unsubscribe();
    }

    private onFinish = async (values: any) => {
        const { selectedUserRole } = this.state;
        const isGestUser = selectedUserRole ? selectedUserRole === UserRole.GUEST_USER : false;

        if (!values || !values?.firstName || !values?.lastName || (!isGestUser && !values?.email) || (!isGestUser && !values?.password) || !values?.role) {
            console.error("Missing form fields");
            return;
        }

        this.setState({ inProgress: true });

        const createUserResponse: any = await this.createUser({
            firstName: values?.firstName,
            lastName: values?.lastName,
            email: isGestUser ? "" : values?.email,
            password: isGestUser ? "" : values?.password,
            role: values?.role
        });

        this.formRef.current?.resetFields();
        this.setState({ inProgress: false, showNewUsersDialog: false, selectedUserRole: undefined });

        if (createUserResponse?.data?.status === "OK") {
            Toast.success("User created successfully");
        } else if (createUserResponse?.data?.status === "NOT_OK") {
            const errorData: any = createUserResponse?.data?.error;
            Toast.error(errorData?.message, errorData?.data?.errorInfo?.message);
        }
    };

    private onclose = () => {
        this.formRef.current?.resetFields();
        this.setState({ showNewUsersDialog: false });
    };

    private renderUserRoleOptions = () => {
        return getUserRoles().sort((a, b) => a.label.localeCompare(b.label)).map((item) => {
            return <Select.Option value={item.value}>{item.label}</Select.Option>;
        });
    };

    private onRoleChange = (value: UserRole) => {
        this.setState({ selectedUserRole: value });
        if (value === UserRole.GUEST_USER) {
            this.formRef.current?.setFieldsValue({ 'email': '', 'password': '' });
        }
    };

    render(): React.ReactNode {
        const { userRows, showNewUsersDialog, inProgress, isDataLoading, selectedUserRole } = this.state;

        const isGestUser = selectedUserRole ? selectedUserRole === UserRole.GUEST_USER : false;

        return <div className="users-page-container">
            <div className="header">
                <div className="title">Users</div>
                <div className="action-container">
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => this.setState({ showNewUsersDialog: true })}>Create New User</Button>
                </div>
            </div>

            <div className="body">
                <div className="users-table-container">
                    <UsersTable rows={userRows} isLoading={isDataLoading} />
                </div>
            </div>

            <GenericModal title="Create New User" open={showNewUsersDialog} onCancel={this.onclose} width={500}>
                <Form
                    onFinish={this.onFinish}
                    ref={this.formRef}
                    layout="vertical"
                    className='new-user-form'
                >
                    <Form.Item name="firstName" label="First Name" rules={[{ required: true, message: 'First name is required' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="lastName" label="Last Name" rules={[{ required: true, message: 'Last name is required' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="role" label="Role" rules={[{ required: true, message: 'Role is required' }]}>
                        <Select className='user-role-selector' onChange={this.onRoleChange}>
                            {this.renderUserRoleOptions()}
                        </Select>
                    </Form.Item>

                    <Form.Item name="email" label="Email" rules={[{ required: !isGestUser, message: 'Email is required' }, { type: 'email', message: "Not a valid email address" }]} >
                        <Input type="email" disabled={isGestUser} />
                    </Form.Item>

                    <Form.Item name="password" label="Password" rules={[{ required: !isGestUser, message: 'Password is required' }]} >
                        <Input.Password disabled={isGestUser} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ marginTop: 16 }} loading={inProgress}>
                            Submit
                        </Button>
                        <Button type="default" style={{ marginLeft: 16 }} onClick={this.onclose} disabled={inProgress}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            </GenericModal>
        </div>;
    }
}
