export enum UserRole {
    ADMIN = "ADMIN",
    SECURITY_OFFICER = "SECURITY_OFFICER",
    TOOL_ROOM_OFFICER = "TOOL_ROOM_OFFICER",
    USER_LEVEL_0 = "USER_LEVEL_0",
    USER_LEVEL_1 = "USER_LEVEL_1",
    GUEST_USER = "GUEST_USER",

}

export type ServiceInitializationStatus = "NOT_INITIALIZED" | "IN_PROGRESS" | "DONE" | "TIMED_OUT" | "ERROR";

export enum DatabaseCollections {
    REF_DATA = "ref_data",
    USER = "user",
    ITEM = "item",
    GATE_PASS = "gate_pass",
    GATE_PASS_ITEM = "gate_pass_item",
    GATE_PASS_HISTORY = "gate_pass_history"

}

export interface HTTPCallableFunctionResponse {
    status: "OK" | "NOT_OK",
    message?: string;
    data?: any;
    error?: {
        code: string;
        message: string;
        data?: any;
    };
}

export interface ParsedGatePassData {
    gatePassNo: string;
    holderUser: string;
    createdByUser: string;
    approverUser: string;
    type: string;
    checkoutDate: string;
    returnDate: string;
    location: string;
    reason: string;
    vehicleNo: string;
    status: string;
    createdAt: string;
}