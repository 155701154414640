import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import { Config } from "../../config";
import { Firestore, getFirestore } from 'firebase/firestore';
import { Auth, getAuth } from "firebase/auth";
import { Functions, HttpsCallable, getFunctions, httpsCallable } from 'firebase/functions';
import "firebase/firestore";


export class FirebaseService {

    private static instance: FirebaseService | null;
    private firebaseConfig: any;
    private app: FirebaseApp | null;
    private analytics: Analytics | null;
    private database: Firestore | null;
    private authentication: Auth | null;
    private functions: Functions | null;

    private constructor() {
        FirebaseService.instance = null;
        this.firebaseConfig = Config.firebase;
        this.app = null;
        this.analytics = null;
        this.database = null;
        this.authentication = null;
        this.functions = null;
    }


    public static getInstance(): FirebaseService {
        if (!this.instance) {
            this.instance = new FirebaseService();
        }

        return this.instance;
    }


    public init(): void {
        this.app = initializeApp(this.firebaseConfig);
        this.analytics = getAnalytics(this.app);
        this.database = getFirestore(this.app);
        this.authentication = getAuth(this.app);
        this.functions = getFunctions(this.app);

        // Connect to emulator if running locally
        // if (window.location.hostname === 'localhost') {
        //     connectFunctionsEmulator(this.functions, 'localhost', 5001);
        // }
    }

    public getFirebaseApp(): FirebaseApp {

        if (!this.app) {
            throw new Error("Firebase App is NOT inititialized");
        }

        return this.app;
    }

    public getDatabase(): Firestore {

        if (!this.database) {
            throw new Error("Database is NOT inititialized");
        }

        return this.database;
    }

    public getAuthentication(): Auth {

        if (!this.authentication) {
            throw new Error("Authentication is NOT inititialized");
        }

        return this.authentication;
    }

    public getFunctions(): Functions {

        if (!this.functions) {
            throw new Error("Functions is NOT inititialized");
        }

        return this.functions;
    }

    public getHTTPSCallableFunction(functionName: string): HttpsCallable {

        if (!functionName) {
            throw new Error("Invalid function name");
        }

        if (!this.functions) {
            throw new Error("Functions is NOT inititialized");
        }

        return httpsCallable(this.functions, functionName);
    }



}