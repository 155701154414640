import { Modal } from "antd";
import React from "react";

interface GenericModalProps {
    title: string;
    open: boolean;
    width?: string | number;
    onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactNode;
}

interface GenericModalState { }

export class GenericModal extends React.Component<GenericModalProps, GenericModalState> {
    render(): React.ReactNode {
        const { title, open, children, width, onCancel } = this.props;
        return (
            <Modal title={title} open={open} onCancel={onCancel} footer={null} width={width ?? 520} maskClosable={false} destroyOnClose={true}>
                {children}
            </Modal>
        );
    }
}
