import { DeleteOutlined, DownloadOutlined, EditOutlined, SelectOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, Space, Table, TableColumnsType, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { ParsedGatePassData } from '../../defs/common';
import { GatePassManagerService, GatePassStatus, GatePassType } from "../../services/common/GatePassManagerService";
import { formatDate, formatDateTimeEpoch, getGatePassStatusDisplayValue, getGatePassTypeDisplayValue, getUserNameById, isDateOlderThanToday } from "../../utils/utils";
import { GatePassDownloadView } from '../GatePassDownloadView/GatePassDownloadView';
import { GenericConfirmationModal } from '../GenericConfirmationModal/GenericConfirmationModal';
import { GenericModal } from '../GenericModal/GenericModal';
import { Toast } from '../Toast/Toast';
import './GenericGatePassTable.scss';

const tableHeight = window.innerHeight - 200;

const TextFilter: React.FC<{ setSelectedKeys: any; selectedKeys: any; confirm: () => void; }> = ({ setSelectedKeys, selectedKeys, confirm }) => (
    <Input
        placeholder="Search"
        value={selectedKeys[0] || ''}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={confirm}
        onBlur={confirm}
    />
);

const { RangePicker } = DatePicker;
const DateRangeFilter: React.FC<{ onChange: (dates: any) => void; }> = ({ onChange }) => (
    <Space>
        <RangePicker onChange={onChange} />
    </Space>
);

export interface GenericGatePassTableData {
    key: string;
    id: string;
    holderUserId: string;
    createdBy: string;
    approverUserId: string;
    type: string;
    checkoutDate: string;
    returnDate: string;
    location: string;
    reason: string;
    vehicleNo: string;
    status: GatePassStatus;
    createdAt: string;
}

interface GenericGatePassTableProps {
    gatePassRows: any[];
    isEditAllowed?: boolean;
    isDeleteAllowed?: boolean;
    onGatePassView: (data: GenericGatePassTableData) => void;
    onGatePassEdit?: (data: GenericGatePassTableData) => void;
    onDisplayDataChange?: (data: ParsedGatePassData[]) => void;
}

interface GenericGatePassTableState {
    rows: any[];
    usersMap: Map<string, any>;
    showDownloadView: boolean;
    showDeleteConfirmation: boolean;
    deleteInProgress: boolean;
    selectedGatePassData: ParsedGatePassData | undefined;
}

export class GenericGatePassTable extends React.Component<GenericGatePassTableProps, GenericGatePassTableState> {
    constructor(props: GenericGatePassTableProps) {
        super(props);

        this.state = {
            rows: this.props.gatePassRows.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
            usersMap: new Map(),
            showDownloadView: false,
            showDeleteConfirmation: false,
            deleteInProgress: false,
            selectedGatePassData: undefined
        };
    }

    componentDidMount(): void {
        this.onDisplayDataChange(this.state.rows);
    }

    componentDidUpdate(prevProps: Readonly<GenericGatePassTableProps>): void {
        if (this.props.gatePassRows !== prevProps.gatePassRows) {
            const rows = this.props.gatePassRows.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            this.setState({ rows }, () => this.onDisplayDataChange(rows));
        }
    }

    private onView = (data: any) => {
        this.props?.onGatePassView(data);
    };

    private onDownload = (data: GenericGatePassTableData) => {
        this.setState({ showDownloadView: true, selectedGatePassData: this.parseGatePassData(data) });
    };

    private onDownloadModalClose = () => {
        this.setState({ showDownloadView: false, selectedGatePassData: undefined });
    };

    private onEdit = (data: any) => {
        this.props?.onGatePassEdit?.(data);
    };

    private onDelete = (data: any) => {
        this.setState({ showDeleteConfirmation: true, selectedGatePassData: this.parseGatePassData(data) });
    };

    private closeDeleteConfirmationModal = (): void => {
        this.setState({ showDeleteConfirmation: false, selectedGatePassData: undefined });
    };

    private handleDateFilter = (key: string, dates: any, setSelectedKeys: any, confirm: () => void) => {
        setSelectedKeys(dates ? [dates] : []);
        confirm();
    };

    private handleChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: GenericGatePassTableData[]; }) => {
        this.onDisplayDataChange(extra?.currentDataSource);
    };

    private onDisplayDataChange = (data: GenericGatePassTableData[]) => {
        let changedRows: ParsedGatePassData[] = [];
        if (data) {
            data.forEach((item: GenericGatePassTableData) => {
                changedRows.push(this.parseGatePassData(item));
            });
        }
        this.props.onDisplayDataChange?.(changedRows);
    };

    /**
     * To get gate pass data in readable format
     * Ex: get user names from user ids, readable type and status string, formatted dates
     * @param data : gate pass table data
     * @returns 
     */
    private parseGatePassData = (data: GenericGatePassTableData): ParsedGatePassData => {
        return {
            gatePassNo: data?.id ?? "",
            holderUser: data?.holderUserId ? getUserNameById(data?.holderUserId) : "",
            createdByUser: data?.createdBy ? getUserNameById(data?.createdBy) : "",
            approverUser: data?.approverUserId ? getUserNameById(data?.approverUserId) : "",
            type: data?.type ? getGatePassTypeDisplayValue(data?.type) : "",
            checkoutDate: data?.checkoutDate ? formatDate(parseInt(data?.checkoutDate)) : "",
            returnDate: data?.returnDate ? formatDate(parseInt(data?.returnDate)) : "",
            location: data?.location ?? "",
            reason: data?.reason ?? "",
            vehicleNo: data?.vehicleNo ?? "",
            status: data?.status ? getGatePassStatusDisplayValue(data?.status) : "",
            createdAt: data?.createdAt ? formatDateTimeEpoch(parseInt(data?.createdAt)) : ""
        };
    };

    private deleteGatePass = () => {
        const { selectedGatePassData } = this.state;

        if (!selectedGatePassData) {
            console.error("Couldn't find selected gate pass data");
            return;
        }

        this.setState({ deleteInProgress: true });

        GatePassManagerService.getInstance().deleteGatePass(selectedGatePassData?.gatePassNo).then(() => {
            Toast.success("Gate pass deleted successfully");
            this.setState({ deleteInProgress: false, showDeleteConfirmation: false, selectedGatePassData: undefined });
        }).catch((error: any) => {
            console.error("Gate pass deletion failed", error ?? "");
            Toast.error("Gate pass deletion failed");
            this.setState({ deleteInProgress: false });
        });
    };

    private rowClassName = (record: GenericGatePassTableData) => {
        if (record.type === GatePassType.RETURNABLE &&
            ![
                GatePassStatus.NON_RETURNABLE_ISSUED,
                GatePassStatus.FULLY_RECEIVED,
                GatePassStatus.DELETED,
                GatePassStatus.REJECTED
            ].includes(record.status) &&
            isDateOlderThanToday(parseInt(record.returnDate))) {
            return 'row-return-date-exceeded';
        }
        return '';
    };

    render(): React.ReactNode {
        const { rows, selectedGatePassData, showDownloadView, showDeleteConfirmation, deleteInProgress } = this.state;

        const deleteConfirmationMessage: string = `Do you want to DELETE the Gate Pass: ${selectedGatePassData?.gatePassNo}?`;

        const gatePassColumns: TableColumnsType<GenericGatePassTableData> = [
            {
                title: 'Gate Pass No',
                dataIndex: 'id',
                key: 'id',
                width: 150,
                sorter: (a, b) => a.id.localeCompare(b.id),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(rows.map(row => row.id))].map(name => ({ text: name, value: name })),
                onFilter: (value, record) => record.id.includes(value as string),
                filterSearch: true
                // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                //     <TextFilter
                //         setSelectedKeys={setSelectedKeys}
                //         selectedKeys={selectedKeys}
                //         confirm={confirm}
                //     />
                // ),
                // onFilter: (value, record) => (record.id || '').toLowerCase().includes((value as string).toLowerCase())
            },
            {
                title: 'Creator',
                dataIndex: 'createdBy',
                key: 'createdBy',
                width: 180,
                render: (value) => { return getUserNameById(value); },
                sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(rows.map(row => getUserNameById(row.createdBy)))].map(name => ({ text: name, value: name })),
                onFilter: (value, record) => getUserNameById(record.createdBy).includes(value as string),
                filterSearch: true
            },
            {
                title: 'Approver',
                dataIndex: 'approverUserId',
                key: 'approverUserId',
                width: 180,
                render: (value) => { return getUserNameById(value); },
                sorter: (a, b) => a.approverUserId.localeCompare(b.approverUserId),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(rows.map(row => getUserNameById(row.approverUserId)))].map(name => ({ text: name, value: name })),
                onFilter: (value, record) => getUserNameById(record.approverUserId).includes(value as string),
                filterSearch: true
            },
            {
                title: 'Holder',
                dataIndex: 'holderUserId',
                key: 'holderUserId',
                width: 180,
                render: (value) => { return getUserNameById(value); },
                sorter: (a, b) => a.holderUserId.localeCompare(b.holderUserId),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(rows.map(row => getUserNameById(row.holderUserId)))].map(name => ({ text: name, value: name })),
                onFilter: (value, record) => getUserNameById(record.holderUserId).includes(value as string),
                filterSearch: true
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                width: 150,
                render: (value) => { return getGatePassTypeDisplayValue(value); },
                sorter: (a, b) => a.type.localeCompare(b.type),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(rows.map(row => getGatePassTypeDisplayValue(row.type)))].map(type => ({ text: type, value: type })),
                onFilter: (value, record) => getGatePassTypeDisplayValue(record.type) === (value as string),
                filterSearch: true
            },
            {
                title: 'Check Out Date',
                dataIndex: 'checkoutDate',
                key: 'checkoutDate',
                width: 150,
                render: (value) => { return formatDate(value); },
                sorter: (a, b) => new Date(a.checkoutDate).getTime() - new Date(b.checkoutDate).getTime(),
                sortDirections: ['descend', 'ascend'],
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                    <DateRangeFilter
                        onChange={(dates) => this.handleDateFilter('checkoutDate', dates, setSelectedKeys, confirm)}
                    />
                ),
                onFilter: (value: any, record) => {
                    const [start, end] = value as [moment.Moment, moment.Moment];
                    return new Date(parseInt(record.checkoutDate) * 1000) >= start.toDate() && new Date(parseInt(record.checkoutDate) * 1000) <= end.toDate();
                }
            },
            {
                title: 'Return Date',
                dataIndex: 'returnDate',
                key: 'returnDate',
                width: 150,
                render: (value) => { return formatDate(value); },
                sorter: (a, b) => new Date(a.returnDate).getTime() - new Date(b.returnDate).getTime(),
                sortDirections: ['descend', 'ascend'],
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                    <DateRangeFilter
                        onChange={(dates) => this.handleDateFilter('checkoutDate', dates, setSelectedKeys, confirm)}
                    />
                ),
                onFilter: (value: any, record) => {
                    const [start, end] = value as [moment.Moment, moment.Moment];
                    return new Date(parseInt(record.returnDate) * 1000) >= start.toDate() && new Date(parseInt(record.returnDate) * 1000) <= end.toDate();
                }
            },
            {
                title: 'Location',
                dataIndex: 'location',
                key: 'location',
                width: 180,
                sorter: (a, b) => a.location.localeCompare(b.location),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(rows.map(row => row.location))].map(name => ({ text: name, value: name })),
                onFilter: (value, record) => record.location.includes(value as string),
                filterSearch: true
                // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                //     <TextFilter
                //         setSelectedKeys={setSelectedKeys}
                //         selectedKeys={selectedKeys}
                //         confirm={confirm}
                //     />
                // ),
                // onFilter: (value, record) => (record.location || '').toLowerCase().includes((value as string).toLowerCase())
            },
            {
                title: 'Reason',
                dataIndex: 'reason',
                key: 'reason',
                width: 200,
                sorter: (a, b) => a.reason.localeCompare(b.reason),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(rows.map(row => row.reason))].map(name => ({ text: name, value: name })),
                onFilter: (value, record) => record.reason.includes(value as string),
                filterSearch: true
                // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                //     <TextFilter
                //         setSelectedKeys={setSelectedKeys}
                //         selectedKeys={selectedKeys}
                //         confirm={confirm}
                //     />
                // ),
                // onFilter: (value, record) => (record.reason || '').toLowerCase().includes((value as string).toLowerCase())
            },
            {
                title: 'Vehicle No',
                dataIndex: 'vehicleNo',
                key: 'vehicleNo',
                width: 120,
                sorter: (a, b) => (a.vehicleNo || '').localeCompare(b.vehicleNo || ''),
                sortDirections: ['descend', 'ascend'],
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                    <TextFilter
                        setSelectedKeys={setSelectedKeys}
                        selectedKeys={selectedKeys}
                        confirm={confirm}
                    />
                ),
                onFilter: (value, record) => (record.vehicleNo || '').toLowerCase().includes((value as string).toLowerCase())
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                width: 180,
                render: (value) => { return getGatePassStatusDisplayValue(value); },
                sorter: (a, b) => a.status.localeCompare(b.status),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(rows.map(row => getGatePassStatusDisplayValue(row.status)))].map(status => ({ text: status, value: status })),
                onFilter: (value, record) => getGatePassStatusDisplayValue(record.status) === (value as string),
                filterSearch: true
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 190,
                render: (value) => { return formatDateTimeEpoch(value); },
                sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
                sortDirections: ['descend', 'ascend'],
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                    <DateRangeFilter
                        onChange={(dates) => this.handleDateFilter('checkoutDate', dates, setSelectedKeys, confirm)}
                    />
                ),
                onFilter: (value: any, record) => {
                    const [start, end] = value as [moment.Moment, moment.Moment];
                    return new Date(record.createdAt) >= start.toDate() && new Date(record.createdAt) <= end.toDate();
                }
            },
            {
                title: 'Actions',
                key: 'action',
                fixed: 'right',
                width: 90,
                render: (text, record) => {
                    return <div className="action-container">
                        <Tooltip title="View">
                            <Button className="view-btn" type="primary" shape="circle" size="small" icon={<SelectOutlined />} onClick={() => this.onView(record)} />
                        </Tooltip>

                        <Tooltip title="Download">
                            <Button className="download-btn" type="primary" shape="circle" size="small" icon={<DownloadOutlined />} onClick={() => this.onDownload(record)} />
                        </Tooltip>

                        {this.props.isEditAllowed && [
                            GatePassStatus.PENDING_APPROVAL,
                            GatePassStatus.CHANGE_REQUESTED_AT_APPROVAL,
                            GatePassStatus.CHANGE_REQUESTED_AT_CHECKOUT
                        ].includes(record.status) &&
                            <Tooltip title="Edit">
                                <Button className="edit-btn" type="primary" shape="circle" size="small" icon={<EditOutlined />} onClick={() => this.onEdit(record)} />
                            </Tooltip>
                        }

                        {this.props.isDeleteAllowed && [
                            GatePassStatus.PENDING_APPROVAL,
                            GatePassStatus.PENDING_CHECKOUT,
                            GatePassStatus.CHANGE_REQUESTED_AT_APPROVAL,
                            GatePassStatus.CHANGE_REQUESTED_AT_CHECKOUT
                        ].includes(record.status) &&
                            <Tooltip title="Delete">
                                <Button className="delete-btn" type="primary" shape="circle" size="small" icon={<DeleteOutlined />} onClick={() => this.onDelete(record)} />
                            </Tooltip>
                        }
                    </div>;
                }
            }
        ];

        return <div>
            <Table
                className="generic-gate-pass-table"
                columns={gatePassColumns}
                dataSource={rows}
                pagination={false}
                scroll={{ x: 'max-content', y: tableHeight }}
                onChange={this.handleChange as any}
                rowClassName={this.rowClassName}
                bordered
            />

            <GenericModal title={`Download Gate Pass: ${selectedGatePassData?.gatePassNo}`} open={showDownloadView} onCancel={this.onDownloadModalClose} width={500}>
                {selectedGatePassData && <GatePassDownloadView gatePassData={selectedGatePassData} />}
            </GenericModal>

            <GenericConfirmationModal
                show={showDeleteConfirmation}
                title={"Delete Confirmation"}
                description={deleteConfirmationMessage}
                type="warning"
                onCancel={this.closeDeleteConfirmationModal}
                isLoading={deleteInProgress}
                onConfirm={this.deleteGatePass}
            />
        </div>;
    }
}