import { CheckCircleFilled, ClockCircleFilled, CloseCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { Timeline } from 'antd';
import { TimelineProps } from 'antd/lib/timeline';
import React, { Component } from 'react';
import { GatePassHistoryEvent, GatePassManagerService, GatePassStatus } from '../../services/common/GatePassManagerService';
import { formatDateTimeEpoch, getGatePassTimeLineStatusDisplayValue, getUserNameById } from '../../utils/utils';
import "./GatePassTimeline.scss";

export interface TimelineItem {
    color?: string;
    dot?: React.ReactNode;
    label?: React.ReactNode;
    children: React.ReactNode;
}

interface GatePassTimelineProps extends TimelineProps {
    gatePassNo: string;
}

interface GatePassTimelineState {
    currentGatePassHistoryData: TimelineItem[];
    isGatePassHistoryLoading: boolean;
}

export class GatePassTimeline extends Component<GatePassTimelineProps, GatePassTimelineState> {
    constructor(props: GatePassTimelineProps) {
        super(props);

        this.state = {
            currentGatePassHistoryData: [],
            isGatePassHistoryLoading: false
        };
    }

    componentDidMount(): void {
        this.getGatePassHistoryData(this.props.gatePassNo);
    }

    componentDidUpdate(prevProps: Readonly<GatePassTimelineProps>): void {
        if (this.props.gatePassNo !== prevProps.gatePassNo) {
            this.getGatePassHistoryData(this.props.gatePassNo);
        }
    }

    private getGatePassHistoryData = (gatePassNo: string) => {
        this.setState({ currentGatePassHistoryData: [], isGatePassHistoryLoading: true });
        GatePassManagerService.getInstance().getEventHistoryOfTheGatePass(gatePassNo).then((data: GatePassHistoryEvent[]) => {
            const historyData: TimelineItem[] = data.sort((a, b) => a.committedAt - b.committedAt)
                .map((item: GatePassHistoryEvent) => {
                    return {
                        dot: this.getHistoryIcon(item.status),
                        color: this.getActionColor(item.status),
                        label: this.getActionLabel(getUserNameById(item.committedABy), formatDateTimeEpoch(item.committedAt)),
                        children: getGatePassTimeLineStatusDisplayValue(item)
                    };
                });
            this.setState({ currentGatePassHistoryData: historyData, isGatePassHistoryLoading: false });
        }).catch((error: any) => {
            console.error("Error retrieving gate pass history", error ?? undefined);
            this.setState({ currentGatePassHistoryData: [], isGatePassHistoryLoading: false });
        });
    };

    private getActionColor = (status: GatePassStatus) => {
        switch (status) {
            case GatePassStatus.PENDING_APPROVAL:
            case GatePassStatus.PENDING_CHECKOUT:
            case GatePassStatus.NON_RETURNABLE_ISSUED:
            case GatePassStatus.PENDING_RECEPTION:
            case GatePassStatus.FULLY_RECEIVED:
                return "green";
            case GatePassStatus.REJECTED:
            case GatePassStatus.DELETED:
                return "red";
            case GatePassStatus.CHANGE_REQUESTED_AT_APPROVAL:
            case GatePassStatus.CHANGE_REQUESTED_AT_CHECKOUT:
            case GatePassStatus.PARTIALLY_RECEIVED:
                return "orange";
            default:
                return "blue";
        }
    };

    private getHistoryIcon = (status: GatePassStatus): React.ReactNode => {
        switch (status) {
            case GatePassStatus.PENDING_APPROVAL:
            case GatePassStatus.PENDING_CHECKOUT:
            case GatePassStatus.NON_RETURNABLE_ISSUED:
            case GatePassStatus.PENDING_RECEPTION:
            case GatePassStatus.FULLY_RECEIVED:
                return <CheckCircleFilled />;
            case GatePassStatus.REJECTED:
            case GatePassStatus.DELETED:
                return <CloseCircleFilled />;
            case GatePassStatus.CHANGE_REQUESTED_AT_APPROVAL:
            case GatePassStatus.CHANGE_REQUESTED_AT_CHECKOUT:
                return <MinusCircleFilled />;
            case GatePassStatus.PARTIALLY_RECEIVED:
                return <ClockCircleFilled />;
            default:
                return <></>;
        }
    };

    private getActionLabel = (userName: string, date: string): React.ReactNode => {
        return <div className="gate-pass-timeline-label">
            <div className="username">{userName}</div>
            <div className="date">{date}</div>
        </div>;
    };

    render() {
        const { currentGatePassHistoryData } = this.state;
        return (
            <Timeline className='generic-timeline-container' mode='right'>
                {currentGatePassHistoryData.map((item, index) => (
                    <Timeline.Item key={index} color={item.color} label={item.label} dot={item.dot ?? undefined}>
                        {item.children}
                    </Timeline.Item>
                ))}
            </Timeline>
        );
    }
}