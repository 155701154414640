import { HistoryOutlined } from '@ant-design/icons';
import { Button, Input, Table, TableColumnsType, Tooltip } from "antd";
import React from "react";
import { Subscription } from "rxjs";
import { GenericModal } from "../../../components/GenericModal/GenericModal";
import { ItemHistoryView } from "../../../components/ItemHistoryView/ItemHistoryView";
import { ItemManagerService } from "../../../services/admin/ItemManagerService";
import { getUnitOfMeasurementDisplayValue } from '../../../utils/utils';
import './ItemsTable.scss';

const tableHeight = window.innerHeight - 200;

const TextFilter: React.FC<{ setSelectedKeys: any; selectedKeys: any; confirm: () => void; }> = ({ setSelectedKeys, selectedKeys, confirm }) => (
    <Input
        placeholder="Search"
        value={selectedKeys[0] || ''}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={confirm}
        onBlur={confirm}
    />
);

export interface ItemsTableData {
    key: string;
    itemCode: string;
    itemName: string;
    serialNumber: string;
    measurementUnit: string;
}

interface ItemsTableProps { }

interface ItemsTableState {
    rows: any[];
    isLoading: boolean;
    showItemHistoryDialog: boolean;
    selectedItem: any;
    selectedItemCode: string;
}

export class ItemsTable extends React.Component<ItemsTableProps, ItemsTableState> {
    private itemsSubscription?: Subscription;

    constructor(props: ItemsTableProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            showItemHistoryDialog: false,
            selectedItem: {},
            selectedItemCode: "string"
        };
    }

    componentDidMount(): void {
        this.itemsSubscription = ItemManagerService.getInstance()
            .getItemsDataObservable()
            .subscribe((items) => {
                const rows = items.map((item, index) => { return { ...item, key: index }; }).sort((a, b) => a.itemCode.localeCompare(b.itemCode));
                this.setState({ rows });
            });
    }

    componentWillUnmount(): void {
        this.itemsSubscription?.unsubscribe();
    }

    private onHistoryView = (itemCode: string) => {
        this.setState({ selectedItemCode: itemCode, showItemHistoryDialog: true });
    };

    render(): React.ReactNode {
        const { rows, isLoading, showItemHistoryDialog, selectedItemCode } = this.state;

        const columns: TableColumnsType<ItemsTableData> = [
            {
                title: 'Item Code',
                dataIndex: 'itemCode',
                key: 'itemCode',
                width: 120,
                sorter: (a, b) => a.itemCode.localeCompare(b.itemCode),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(rows.map(row => row.itemCode))].map(name => ({ text: name, value: name })),
                onFilter: (value, record) => record.itemCode.includes(value as string),
                filterSearch: true
                // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                //     <TextFilter
                //         setSelectedKeys={setSelectedKeys}
                //         selectedKeys={selectedKeys}
                //         confirm={confirm}
                //     />
                // ),
                // onFilter: (value, record) => (record.itemCode || '').toLowerCase().includes((value as string).toLowerCase())
            },
            {
                title: 'Description',
                dataIndex: 'itemName',
                key: 'itemName',
                width: 180,
                sorter: (a, b) => a.itemName.localeCompare(b.itemName),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(rows.map(row => row.itemName))].map(name => ({ text: name, value: name })),
                onFilter: (value, record) => record.itemName.includes(value as string),
                filterSearch: true
                // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                //     <TextFilter
                //         setSelectedKeys={setSelectedKeys}
                //         selectedKeys={selectedKeys}
                //         confirm={confirm}
                //     />
                // ),
                // onFilter: (value, record) => (record.itemName || '').toLowerCase().includes((value as string).toLowerCase())
            },
            {
                title: 'Serial No',
                dataIndex: 'serialNumber',
                key: 'serialNumber',
                width: 160,
                sorter: (a, b) => a.serialNumber.localeCompare(b.serialNumber),
                sortDirections: ['descend', 'ascend'],
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                    <TextFilter
                        setSelectedKeys={setSelectedKeys}
                        selectedKeys={selectedKeys}
                        confirm={confirm}
                    />
                ),
                onFilter: (value, record) => (record.serialNumber || '').toLowerCase().includes((value as string).toLowerCase())
            },
            {
                title: 'Unit of Measurement',
                dataIndex: 'measurementUnit',
                key: 'measurementUnit',
                width: 160,
                render: (value) => { return getUnitOfMeasurementDisplayValue(value); },
                sorter: (a, b) => a.measurementUnit.localeCompare(b.measurementUnit),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(rows.map(row => getUnitOfMeasurementDisplayValue(row.measurementUnit)))].map(status => ({ text: status, value: status })),
                onFilter: (value, record) => getUnitOfMeasurementDisplayValue(record.measurementUnit) === (value as string),
                filterSearch: true
            },
            {
                title: 'Actions',
                key: 'action',
                fixed: 'right',
                width: 90,
                render: (text, record) => (
                    <div className="action-container">
                        <Tooltip title="View history">
                            <Button className="view-btn" type="primary" shape="circle" size="small" icon={<HistoryOutlined />} onClick={() => this.onHistoryView(record.itemCode)} />
                        </Tooltip>
                    </div>
                )
            }
        ];

        return <>
            <Table columns={columns} dataSource={rows} loading={isLoading} pagination={false} scroll={{ x: 'max-content', y: tableHeight }} bordered />
            <GenericModal title={`Item History: ${selectedItemCode}`} open={showItemHistoryDialog} onCancel={() => this.setState({ showItemHistoryDialog: false })} width={1200}>
                <ItemHistoryView currentItemCode={selectedItemCode} />
            </GenericModal>
        </>;
    }
}