import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, FormInstance, Input, Select } from "antd";
import React from "react";
import { GenericModal } from "../../components/GenericModal/GenericModal";
import { Toast } from "../../components/Toast/Toast";
import { ItemManagerService } from "../../services/admin/ItemManagerService";
import { getUnitOfMeasurements } from "../../utils/utils";
import { ItemsTable } from "./components/ItemsTable";
import "./ItemsPage.scss";

interface ItemsPageProps { }

interface ItemsPageState {
    showNewItemDialog: boolean;
    inProgress: boolean;
}

export class ItemsPage extends React.Component<ItemsPageProps, ItemsPageState> {
    private formRef: React.RefObject<FormInstance>;

    constructor(props: ItemsPageProps) {
        super(props);
        this.state = {
            showNewItemDialog: false,
            inProgress: false
        };

        this.formRef = React.createRef();
    }

    private onFinish = async (values: any) => {
        if (!values || !values?.description || !values?.uom) {
            Toast.error("Missing form fields", "Please fill all the required fields");
            console.error("Missing form fields");
            return;
        }

        this.setState({ inProgress: true });

        ItemManagerService.getInstance()
            .createItem({
                itemName: values?.description,
                serialNumber: values?.serialNo ?? "",
                measurementUnit: values?.uom,
            })
            .then(() => {
                Toast.success("Item created successfully");
            })
            .catch((error: any) => {
                console.error("Item creation failed", error ?? "");
                Toast.error("Item creation failed");
            })
            .finally(() => {
                this.formRef.current?.resetFields();
                this.setState({ inProgress: false, showNewItemDialog: false });
            });
    };

    private onClose = () => {
        this.formRef.current?.resetFields();
        this.setState({ showNewItemDialog: false });
    };

    private renderUOMOptions = () => {
        return getUnitOfMeasurements().sort((a, b) => a.label.localeCompare(b.label)).map((item) => {
            return <Select.Option value={item.value}>{item.label}</Select.Option>;
        });
    };

    render(): React.ReactNode {
        const { showNewItemDialog, inProgress } = this.state;

        return (
            <div className="items-page-container">
                <div className="header">
                    <div className="title">Items</div>
                    <div className="action-container">
                        <Button type="primary" icon={<PlusOutlined />} onClick={() => this.setState({ showNewItemDialog: true })}>
                            Create New Item
                        </Button>
                    </div>
                </div>

                <div className="body">
                    <div className="items-table-container">
                        <ItemsTable />
                    </div>
                </div>

                <GenericModal title="Create New Item" open={showNewItemDialog} onCancel={this.onClose} width={500}>
                    <Form ref={this.formRef} layout="vertical" onFinish={this.onFinish}>
                        <Form.Item name="description" label="Description" required>
                            <Input />
                        </Form.Item>

                        <Form.Item name="serialNo" label="Serial No">
                            <Input />
                        </Form.Item>

                        <Form.Item name="uom" label="Unit of Measurement" required>
                            <Select>
                                {this.renderUOMOptions()}
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ marginTop: 16 }} loading={inProgress}>
                                Submit
                            </Button>
                            <Button type="default" style={{ marginLeft: 16 }} onClick={this.onClose}>
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </GenericModal>
            </div>
        );
    }
}
