import { ViewGatePassForm } from "../../components/GatePassForms/ViewGatePassForm/ViewGatePassForm";
import { BaseGatePassPage } from "../BaseGatePassPage/BaseGatePassPage";
import './MyGatePassesPage.scss';

export class MyGatePassesPage extends BaseGatePassPage {

    protected processGatePasses = (gatePasses: any[]): any[] => {
        return gatePasses
            .filter((gatePass: any) => { return gatePass.createdBy === this.currentUser?.userId; });
    };

    protected getPageContainerClassName = (): string => {
        return "my-gate-passes-page-container";
    };

    protected isEditAllowed = (): boolean => {
        return true;
    };

    protected isDeleteAllowed = (): boolean => {
        return true;
    };

    protected getPageTitle = (): string => {
        return "My Gate Passes";
    };

    protected getGatePassActionForm = (): JSX.Element => {
        const { currentGatePassId, selectedRow } = this.state;
        return (
            <ViewGatePassForm gatePassNo={currentGatePassId} onFormClose={this.closeGatePassActionModal} gatePassData={selectedRow} />
        );
    };
}