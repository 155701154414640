import { ReturnGatePassForm } from "../../components/GatePassForms/ReturnGatePassForm/ReturnGatePassForm";
import { GatePassStatus } from "../../services/common/GatePassManagerService";
import { BaseGatePassPage } from "../BaseGatePassPage/BaseGatePassPage";
import './PendingReturnsPage.scss';

export class PendingReturnsPage extends BaseGatePassPage {

    protected processGatePasses = (gatePasses: any[]): any[] => {
        return gatePasses
            .filter((gatePass: any) => { return [GatePassStatus.PENDING_RECEPTION, GatePassStatus.PARTIALLY_RECEIVED].includes(gatePass.status) });
    }

    protected getPageContainerClassName = (): string => {
        return "pending-returns-page-container"
    }

    protected getPageTitle = (): string => {
        return "Pending Returns";
    };

    protected getGatePassActionForm = (): JSX.Element => {
        const { currentGatePassId, selectedRow } = this.state;
        return (
            <ReturnGatePassForm
                gatePassNo={currentGatePassId}
                gatePassData={selectedRow}
                onFormClose={this.closeGatePassActionModal}
            />
        );
    }
}