import { Button, Divider, Form, FormInstance, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { createRef, RefObject } from "react";
import { GatePassItemsTable } from "../../../components/GatePassItemsTable/GatePassItemsTable";
import { GatePassTimeline } from "../../../components/GatePassTimeline/GatePassTimeline";
import './ViewGatePassForm.scss';

interface ViewGatePassFormProps {
    gatePassNo: string;
    gatePassData: any;
    onFormClose: () => void;
}

interface ViewGatePassFormState { }

export class ViewGatePassForm extends React.Component<ViewGatePassFormProps, ViewGatePassFormState> {
    private formRef: React.RefObject<FormInstance>;
    private componentRef: RefObject<HTMLDivElement>;

    constructor(props: ViewGatePassFormProps) {
        super(props);

        this.state = {};

        this.formRef = React.createRef();
        this.componentRef = createRef<HTMLDivElement>();
    }

    componentDidMount(): void {
        this.formRef.current?.setFieldsValue(this.props.gatePassData);
    }

    componentDidUpdate(prevProps: Readonly<ViewGatePassFormProps>): void {
        if (this.props.gatePassData !== prevProps.gatePassData) {
            this.formRef.current?.setFieldsValue(this.props.gatePassData);
        }
    }

    private onFormClose = () => {
        this.formRef.current?.resetFields();
        this.props.onFormClose();
    };

    render(): React.ReactNode {
        const { gatePassNo, gatePassData } = this.props;

        return (
            <div>
                <Form
                    ref={this.formRef}
                    layout="vertical"
                    className='view-gate-pass-form'
                >
                    <div ref={this.componentRef}>
                        <Form.Item name="type" label="">
                            <div className='gate-pass-no-display'>{`Gate Pass No: ${gatePassNo}`}</div>
                        </Form.Item>

                        <div className='gate-pass-form-row'>
                            <Form.Item name="type" label="Gate Pass Type">
                                <Input disabled />
                            </Form.Item>

                            <Form.Item name="checkOutDate" label="Check Out Date">
                                <Input disabled />
                            </Form.Item>

                            <Form.Item name="returnDate" label="Return Date">
                                <Input disabled />
                            </Form.Item>

                            <Form.Item name="vehicleNo" label="Vehicle No">
                                <Input disabled />
                            </Form.Item>
                        </div>

                        <Form.Item name="location" label="Location">
                            <Input disabled />
                        </Form.Item>

                        <Form.Item name="reason" label="Reason">
                            <TextArea rows={2} disabled />
                        </Form.Item>

                        <Form.Item name="holderName" label="Gate Pass Holder">
                            <Input disabled />
                        </Form.Item>

                        <Form.Item name="approverName" label="Gate Pass Approver">
                            <Input disabled />
                        </Form.Item>

                        <Divider></Divider>

                        <GatePassItemsTable gatePassNo={gatePassNo} gatePassType={gatePassData.type} />

                        <Divider></Divider>

                        <GatePassTimeline gatePassNo={gatePassNo} />
                    </div>

                    <Form.Item className="action-container">
                        <Button type="default" onClick={this.onFormClose}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}