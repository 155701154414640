import { Component, ReactNode, ErrorInfo } from 'react';

interface ErrorBoundaryState {
    hasError: boolean;
    error?: Error;
}

interface ErrorBoundaryProps {
    children: ReactNode;
    fallback?: ReactNode;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            error: undefined,
        };
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { hasError: true, error };
    }

    private getDefaultErrorFallBack = (): ReactNode => {

        return (
            <div className="error-fallback">
                Application Error Occurred! Sorry for the Inconvenience.
            </div>
        )
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // TODO: Set Logger
    }

    render(): ReactNode {
        const { hasError } = this.state;
        const { children, fallback } = this.props;

        if (hasError) {
            return fallback ?? this.getDefaultErrorFallBack();
        }

        return children;
    }
}