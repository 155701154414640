import { ApproveGatePassForm } from "../../components/GatePassForms/ApproveGatePassForm/ApproveGatePassForm";
import { BaseGatePassPage } from "../BaseGatePassPage/BaseGatePassPage";
import { GatePassStatus } from "../../services/common/GatePassManagerService";
import { UserRole } from "../../defs/common";
import './PendingApprovalsPage.scss';


export class PendingApprovalsPage extends BaseGatePassPage {

    protected processGatePasses = (gatePasses: any[]): any[] => {
        return gatePasses
            .filter((gatePass: any) => { return gatePass.status === GatePassStatus.PENDING_APPROVAL; })
            .filter((gatePass: any) => { return this.currentUser?.role === UserRole.USER_LEVEL_0 ? gatePass.approverUserId === this.currentUser?.userId : true; });
    }

    protected getPageContainerClassName = (): string => {
        return "pending-approval-page-container"
    }

    protected getPageTitle = (): string => {
        return "Pending Approvals";
    };

    protected getGatePassActionForm = (): JSX.Element => {
        const { currentGatePassId, selectedRow } = this.state;
        return (
            <ApproveGatePassForm
                gatePassNo={currentGatePassId}
                gatePassData={selectedRow}
                onFormClose={this.closeGatePassActionModal}
            />
        );
    }
}

