import { CheckoutGatePassForm } from "../../components/GatePassForms/CheckoutGatePassForm/CheckoutGatePassForm";
import { GatePassStatus } from "../../services/common/GatePassManagerService";
import { BaseGatePassPage } from "../BaseGatePassPage/BaseGatePassPage";
import './PendingCheckoutsPage.scss';


export class PendingCheckoutsPage extends BaseGatePassPage {


    protected processGatePasses = (gatePasses: any[]): any[] => {
        return gatePasses
            .filter((gatePass: any) => { return gatePass.status === GatePassStatus.PENDING_CHECKOUT });
    }

    protected getPageContainerClassName = (): string => {
        return "pending-checkouts-page-container"
    }

    protected getPageTitle = (): string => {
        return "Pending Checkouts";
    };

    protected getGatePassActionForm = (): JSX.Element => {
        const { currentGatePassId, selectedRow } = this.state;
        return (

            <CheckoutGatePassForm
                gatePassNo={currentGatePassId}
                gatePassData={selectedRow}
                onFormClose={this.closeGatePassActionModal}
            />
        );
    }
}