import { Spin } from "antd";
import "./FullScreenLoader.scss";

export const FullScreenLoader = (): JSX.Element => {
    return (
        <div className="full-screen-loader">
            <Spin size="large" tip="Loading Yugadanavi GPS">
                <div className="loader-inner-container" />
            </Spin>
        </div>
    );
};
