import React from "react";
import { Modal } from 'antd';
import { InfoCircleOutlined, WarningOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import "./GenericConfirmationModal.scss";



interface GenericConfirmationModalProps {
    type?: "info" | "warning" | "danger" | "success";
    show: boolean;
    title: React.ReactNode;
    icon?: React.ReactNode;
    description: React.ReactNode;
    hideIcon?: boolean;
    confirmButtonText?: string;
    cancelButtonText?: string;
    isLoading?: boolean;
    onCancel?: () => void;
    onConfirm?: () => void;



}


export const GenericConfirmationModal: React.FC<GenericConfirmationModalProps> = ({ show, title, icon, type, hideIcon, description, isLoading, confirmButtonText, cancelButtonText, onCancel, onConfirm, }): JSX.Element => {

    const getIcon = (): React.ReactNode => {

        if (hideIcon) {
            return;
        }

        if (icon) {
            return icon;
        }

        switch (type) {
            case "info": return <InfoCircleOutlined />
            case "warning": return <WarningOutlined />
            case "success": return <CheckCircleOutlined />
            case "danger": return <ExclamationCircleOutlined />
            default: return <InfoCircleOutlined />

        }
    }

    const getTitle = (): React.ReactNode => {

        return (
            <div className="title">
                {getIcon()}
                {title}
            </div>
        );
    }

    return (
        <Modal
            className="generic-confirmation-modal"
            title={getTitle()}
            closable={false}
            open={show}
            onOk={onConfirm}
            onCancel={onCancel}
            destroyOnClose={true}
            okText={confirmButtonText ?? "Confirm"}
            cancelText={cancelButtonText ?? "Cancel"}
            cancelButtonProps={{ type: "default", loading: false, icon: undefined, className: "", disabled: isLoading, onClick: onCancel, size: "small" }}
            okButtonProps={{ type: "primary", loading: isLoading, icon: undefined, className: "", disabled: isLoading, onClick: onConfirm, size: "small" }}
            centered={true}

        >
            {description}
        </Modal>
    );
}