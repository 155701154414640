import { Auth } from "firebase/auth";
import { HttpsCallable, HttpsCallableResult } from "firebase/functions";
import { Observable, Subject } from "rxjs";
import { DatabaseCollections, HTTPCallableFunctionResponse, ServiceInitializationStatus, UserRole } from "../../defs/common";
import { FirebaseService } from "../common/FirebaseService";
import { LazyDataCacheService } from "../common/LazyDataCacheService";
import { collection, CollectionReference, DocumentData, Firestore, query, Query } from "firebase/firestore";

interface UserSchema {
    firstName: string;
    lastName: string;
    role: UserRole;
    email: string | null;
    isDeleted: boolean;
    isActive: boolean | null;
    createdAt: number;
    createdBy: string;
    deletedAt: number | null;
    deletedBy: string | null;
    lastLoginAt: number | null;
    userId: string | null;
}


interface InitilizationStatuEvent {
    status: ServiceInitializationStatus;
}


export class UserManagerService {

    private static instance: UserManagerService | null;
    private firebaseService: FirebaseService;
    private auth: Auth;
    private db: Firestore;
    private initializationStatusSubject: Subject<InitilizationStatuEvent> = new Subject<InitilizationStatuEvent>();
    private deleteUser: HttpsCallable;
    private changeActivationStatus: HttpsCallable;
    private updateUserRole: HttpsCallable;

    private userDataCacheService: LazyDataCacheService<UserSchema>;
    private userDataObservable?: Observable<UserSchema[]>;

    private users: Map<string, UserSchema> = new Map();




    private constructor() {
        UserManagerService.instance = null;
        this.firebaseService = FirebaseService.getInstance();
        this.auth = this.firebaseService.getAuthentication();
        this.db = this.firebaseService.getDatabase();
        this.deleteUser = FirebaseService.getInstance().getHTTPSCallableFunction("deleteUser");
        this.changeActivationStatus = FirebaseService.getInstance().getHTTPSCallableFunction("changeActivationStatus");
        this.updateUserRole = FirebaseService.getInstance().getHTTPSCallableFunction("updateUserRole");

        const allUsersQuery: Query = this.getAllUserQuery();
        this.userDataCacheService = new LazyDataCacheService<UserSchema>(allUsersQuery);


        this.users = new Map();
    }

    private getAllUserQuery = (): Query => {
        const userCollectionRef: CollectionReference<DocumentData, DocumentData> = collection(this.db, DatabaseCollections.USER);
        return query(userCollectionRef);
    }

    public getInitializationStatusObservable = (): Observable<InitilizationStatuEvent> => {
        return this.initializationStatusSubject.asObservable();
    };

    public static getInstance(): UserManagerService {
        if (!this.instance) {
            this.instance = new UserManagerService();
        }

        return this.instance;
    }

    public getUserDataObservable = (): Observable<UserSchema[]> => {

        if (!this.userDataObservable) {
            throw new Error("Data Cache Observer is not initialized");
        }

        return this.userDataObservable;
    }

    public getAllUsers = (): UserSchema[] => {
        return Array.from(this.users.values());
    };

    public getAllUsersAsAMap = (): Map<string, UserSchema> => {
        return this.users;
    }

    private handleOnDataEvent = (users: UserSchema[]): void => {
        if (users.length === 0) {
            this.users.clear();
        }

        for (const user of users) {

            if (!user.userId) {
                // TODO: Log warn
                continue;
            }

            this.users.set(user.userId, { ...user });
        }
    }

    public init = async (): Promise<void> => {
        let timeOut: NodeJS.Timeout | undefined;

        try {
            this.userDataObservable = await this.userDataCacheService.getDataObservable(undefined, this.handleOnDataEvent);

            timeOut = setTimeout(() => {
                this.initializationStatusSubject.next({ status: "DONE" });
                clearTimeout(timeOut);
            });
        } catch (error: any) {

            if (timeOut) {
                clearTimeout(timeOut);
            }

            timeOut = setTimeout(() => {
                this.initializationStatusSubject.next({ status: "ERROR" });
                clearTimeout(timeOut);
            });
        }
    };

    public deleteUserByUserId = async (userId: string): Promise<void> => {
        const response: HttpsCallableResult = await this.deleteUser({ userId });
        const { status, error } = response.data as HTTPCallableFunctionResponse;

        if (status === "NOT_OK") {
            throw new Error(error?.message);
        }
    }

    public changeUserActivationStatus = async (userId: string, activationStatus: "ACTIVATE" | "DEACTIVATE"): Promise<void> => {
        const response: HttpsCallableResult = await this.changeActivationStatus({ userId, activationStatus });
        const { status, error } = response.data as HTTPCallableFunctionResponse;

        if (status === "NOT_OK") {
            throw new Error(error?.message);
        }
    }

    public upgradeUserRole = async (userId: string, role: UserRole): Promise<void> => {
        const response: HttpsCallableResult = await this.updateUserRole({ userId, role });
        const { status, error } = response.data as HTTPCallableFunctionResponse;

        if (status === "NOT_OK") {
            throw new Error(error?.message);
        }
    }

    public getUserNameByUserId = (userId: string): string => {
        const user: UserSchema | undefined = this.users.get(userId);

        if (!user) return "";

        return `${user.firstName} ${user.lastName}`;
    }
}