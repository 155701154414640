import { Firestore, addDoc, collection, deleteDoc, doc, getDocs, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { DatabaseCollections, ServiceInitializationStatus } from "../../defs/common";
import { FirebaseService } from "../common/FirebaseService";

export interface AuthUser {
    userId: string,
    displayName: string;
    email: string;
}


interface InitilizationStatuEvent {
    status: ServiceInitializationStatus;
}

export interface ItemSchema {
    itemCode: string;
    itemName: string;
    serialNumber: string;
    measurementUnit: string;
}

interface ItemData {
    itemName: string;
    serialNumber: string;
    measurementUnit: string;
}

// eslint-disable-next-line no-lone-blocks
interface ItemWithDocId extends ItemSchema {
    docId: string;
}

export class ItemManagerService {

    private static instance: ItemManagerService | null;
    private firebaseService: FirebaseService;
    private db: Firestore;
    private items: ItemWithDocId[];
    private initilizationStatuSubject: Subject<InitilizationStatuEvent>;
    private dataSubject: BehaviorSubject<ItemWithDocId[]>;
    private unsubscribe?: any;


    private constructor() {
        ItemManagerService.instance = null;
        this.firebaseService = FirebaseService.getInstance();
        this.db = this.firebaseService.getDatabase();

        this.initilizationStatuSubject = new Subject<InitilizationStatuEvent>();
        this.dataSubject = new BehaviorSubject<ItemWithDocId[]>([]);



        this.items = [];

    }

    public getInitilizationStatuObservable = (): Observable<InitilizationStatuEvent> => {
        return this.initilizationStatuSubject.asObservable();
    };

    public getItemsDataObservable = (): Observable<ItemWithDocId[]> => {
        return this.dataSubject.asObservable();
    };

    public static getInstance(): ItemManagerService {
        if (!this.instance) {
            this.instance = new ItemManagerService();
        }

        return this.instance;
    }

    public init = async (): Promise<void> => {

        try {
            await this.fetchAllItemsFromDatabase();
            this.subscribeToDatabaseEvents();
            this.initilizationStatuSubject.next({ status: "DONE" });
        } catch (error: any) {
            this.initilizationStatuSubject.next({ status: "ERROR" });
        }

    };

    private publishItemsData = (): void => {
        this.dataSubject.next(this.items);
    };

    public getAllItems = (): ItemWithDocId[] => {
        return this.items;
    };

    private subscribeToDatabaseEvents = (): void => {
        const collectionRef = collection(this.db, DatabaseCollections.ITEM);
        const itemQuery = query(collectionRef);
        this.unsubscribe = onSnapshot(itemQuery, snapshot => {

            snapshot.docChanges().forEach((change) => {
                const index: number = this.items.findIndex(item => item.docId === change.doc.id);

                switch (change.type) {
                    case "added":
                        if (index === -1) {
                            this.items.push({ ...change.doc.data(), docId: change.doc.id } as ItemWithDocId);
                        }
                        break;

                    case "modified":
                        if (index !== -1) {
                            this.items[index] = { ...change.doc.data(), docId: change.doc.id } as ItemWithDocId;
                        }
                        break;
                    case "removed":
                        this.items.splice(index, 1);
                        break;

                    default:
                        break;
                }

            });

            this.publishItemsData();
        });
    };

    private generateUniqueId = async () => {
        const collRef = collection(this.db, DatabaseCollections.ITEM);
        const q = query(collRef, orderBy('itemCode', 'desc'), limit(1));
        const snapshot = await getDocs(q);
        let newId = 1; // Default to 1 if the collection is empty

        if (!snapshot.empty) {
            snapshot.forEach(doc => {
                const lastId = parseInt(doc.data().itemCode, 10); // Ensure lastId is a number
                newId = lastId + 1;
            });
        }

        // Pad the newId to be 8 digits long
        const paddedId = String(newId).padStart(8, '0');
        return paddedId;
    };

    public createItem = async (itemData: ItemData): Promise<string> => {

        const collectionRef = collection(this.db, DatabaseCollections.ITEM);
        const itemCode: string = await this.generateUniqueId();

        const item: ItemSchema = {
            itemName: itemData.itemName,
            measurementUnit: itemData.measurementUnit,
            serialNumber: itemData.serialNumber,
            itemCode,
        };

        await addDoc(collectionRef, item);
        return itemCode;
    };

    public fetchAllItemsFromDatabase = async (): Promise<void> => {

        const collectionRef = collection(this.db, DatabaseCollections.ITEM);
        const docs = await getDocs(collectionRef);

        if (docs.empty) {
            return;

        }

        docs.forEach(doc => {
            this.items.push({ ...doc.data(), docId: doc.id } as ItemWithDocId);
        });

        this.publishItemsData();


    };

    public deleteItem = async (docId: string): Promise<void> => {

        if (!docId) {
            throw new Error("Document ID is required to perform deletion");
        }
        const docRef = doc(this.db, DatabaseCollections.ITEM, docId);
        await deleteDoc(docRef);
    };

    public getItemsByItemCodes = async (itemCodes: string[]): Promise<ItemSchema[]> => {
        const items: ItemSchema[] = [];

        for (const itemCode of itemCodes) {

            const itemCollectionRef = collection(this.db, DatabaseCollections.ITEM);
            const queryByItemCode = query(itemCollectionRef, where("itemCode", "==", itemCode), limit(1));
            const itemDocs = await getDocs(queryByItemCode);

            if (itemDocs.empty) {
                continue;
            }

            itemDocs.forEach(doc => {
                items.push({
                    itemCode: doc.data().itemCode,
                    itemName: doc.data().itemName,
                    measurementUnit: doc.data().measurementUnit,
                    serialNumber: doc.data().serialNumber
                })
            })
        }

        return items;
    }






}